export enum sortTypes {
  void,
  TitleAsc,
  TitleDesc,
  DateAsc,
  DateDesc,
  EntriesAsc,
  EntriesDesc,
  FirstnameAsc,
  FirstnameDesc,
  LastnameAsc,
  LastnameDesc,
  EmailAsc,
  EmailDesc,
  DatetimeAsc,
  DatetimeDesc,
}
