import DashboardButton from '../components/DashboardButton'
import { IconTypes } from '../components/Icon'
import { useAuthContext } from '../api/AuthContext'

const DashboardPage = () => {
  const { user } = useAuthContext()

  return (
    <div id="dashboard" className="h-full w-full ">
      <div className="py-16 text-center">
        <p className="text-2xl">Welcome back {user?.firstname}</p>
      </div>
      <div className="flex justify-center items-center gap-8 flex-col sm:flex-row">
        <DashboardButton
          icon={IconTypes.dashapp}
          title="App manager"
          link="app"
        />

        <DashboardButton
          icon={IconTypes.dashusers}
          title="User manager"
          link="users"
        />
      </div>
    </div>
  )
}

export default DashboardPage
