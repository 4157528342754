import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Toast from 'react-hot-toast'
import { setToken } from '../api/login'
import { API } from '../api/login'
import { useAuthContext } from '../api/AuthContext'
import Button from '../components/Button'
import { LoginSchema, loginSchema } from '../utils/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import FormInput from '../components/FormInput'
import FormError from '../components/FormError'

const LoginPage = () => {
  const navigate = useNavigate()
  const { user, setUser } = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
  })

  useEffect(() => {
    setIsMounted(false)
    setTimeout(() => {
      setIsMounted(true)
    }, 200)
    if (user) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitLogin: SubmitHandler<LoginSchema> = async (formData) => {
    setIsLoading(true)
    try {
      const value = {
        identifier: formData.email,
        password: formData.password,
      }
      const response = await fetch(`${API}/auth/cms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(value),
      })

      const data = await response.json()
      if (data?.error) {
        throw data?.error
      } else {
        setToken(data.jwt)
        // set the user
        // @ts-ignore
        setUser(data.user)
        Toast(`Welcome back ${data.user.firstname || 'User'}`)
        setIsLoading(false)
        navigate('/', { replace: true })
      }
    } catch (error) {
      // @ts-ignore
      Toast.error(error?.message ?? 'Something went wrong!')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="w-full flex flex-col h-full items-center py-20">
      <form
        className={`w-1/2 border flex flex-col gap-4 bg-surface p-10 transition duration-500 ${
          isMounted ? 'opacity-100' : 'opacity-0'
        }`}
        onSubmit={handleSubmit(submitLogin)}
        noValidate
      >
        <p className="text-2xl">Login</p>
        <p className="">Email</p>
        <FormInput
          type="email"
          placeholder="email@address"
          error={Boolean(errors.email)}
          {...register('email')}
        />
        {errors.email && (
          <FormError>{errors.email.message ?? 'Error'}</FormError>
        )}
        <p className="">Password</p>
        <FormInput
          type="password"
          placeholder="password"
          error={Boolean(errors.password)}
          {...register('password')}
        />
        {errors.password && (
          <FormError>{errors.password.message ?? 'Error'}</FormError>
        )}
        <div className="flex justify-between pt-4">
          <Button type="submit" tall loading={isLoading} disabled={isLoading}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default LoginPage
