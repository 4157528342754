import { useState, useEffect } from 'react'
import TitlePanel from '../components/TitlePanel'
import Panel from '../components/Panel'
import SearchBar from '../components/SearchBar'
import Button from '../components/Button'
import Dropdown, { DropdownOption } from '../components/Dropdown'
import Row, { RowType } from '../components/Row'
import Callout from '../components/Callout'
import { sortTypes } from '../types/DropdownTypes'
import collectionsAPI, {
  CollectionsFull,
  getCollectionsFull,
} from '../api/collections'
import ModalCollection from '../components/ModalCollection'
import Pagination from '../components/Pagination'

const AppCollectionsPage = () => {
  const [data, setData] = useState<getCollectionsFull | undefined>(undefined)
  const [search, setSearch] = useState<string>('')
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<CollectionsFull | null>(null)
  const [sortMode, setSortMode] = useState<sortTypes>(sortTypes.TitleAsc)
  const [currentPage, setCurrentPage] = useState<number>(1)

  useEffect(() => {
    if (search) {
      searchForCollections()
    } else {
      getCollections()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortMode, currentPage])

  useEffect(() => {
    if (search === '') searchForCollections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const getCollections = async () => {
    try {
      const fetchData = await collectionsAPI.getCollectionsFull(
        currentPage,
        sortMode
      )
      setData(fetchData)
    } catch (error) {
      console.log(error)
    }
  }

  const searchForCollections = async () => {
    try {
      const fetchData = await collectionsAPI.getFindCollections(
        search,
        currentPage,
        sortMode
      )
      setData(fetchData)
    } catch (error) {
      console.log(error)
    }
  }

  const refresh = () => {
    closeModal()
    getCollections()
    setCurrentPage(1)
  }

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
    setEditModal(null)
  }

  const onSearchCancel = () => {
    setSearch('')
    setCurrentPage(1)
  }

  const onSearchEnter = () => {
    if (currentPage !== 1) {
      setCurrentPage(1)
    } else {
      searchForCollections()
    }
  }

  return (
    <div className="w-full flex flex-col gap-2">
      <TitlePanel
        title="Collections"
        onClick={openModal}
        buttonText="Add new collection"
      />

      <Panel type="mid">
        <Callout>Find a collection</Callout>
        <div className="flex justify-between w-full items-center mt-1">
          <div className="w-full">
            <SearchBar
              value={search}
              onChange={(text: string) => setSearch(text)}
              placeholder="Search collections"
              onEnter={onSearchEnter}
              onCancel={onSearchCancel}
            />
          </div>
          <div className="ml-8">
            <Button variant="outlined" tall onClick={onSearchEnter}>
              Search
            </Button>
          </div>
        </div>
      </Panel>

      <Panel type="bottom">
        <div className="flex justify-between pb-8">
          <Callout>
            {data?.meta?.pagination?.total
              ? data?.meta?.pagination?.total
              : '0'}{' '}
            Collections
          </Callout>
          <Dropdown
            defaultValue=""
            placeholder="Sort by"
            className=""
            onChange={(val: DropdownOption) => {
              setSortMode(val?.value as sortTypes)
            }}
            options={[
              { value: sortTypes.TitleAsc, label: 'Title Ascending' },
              { value: sortTypes.TitleDesc, label: 'Title Descending' },
            ]}
            clearable
            searchable
          />
        </div>

        <div className="gap-3 flex flex-col">
          {data?.data?.map((item: any) => (
            <Row
              type={RowType.collection}
              title={item.attributes.title}
              subtitle={
                item.attributes?.videos?.data.length
                  ? item.attributes?.videos?.data.length + ' Videos'
                  : '0 Videos'
              }
              key={item.id}
              onMenu={() => {
                setEditModal(item)
              }}
              onNavigate={`videos?collection=${item.id}`}
            />
          ))}
        </div>
        {data?.meta?.pagination ? (
          <div className="w-full pt-10 flex justify-end">
            <Pagination
              currentPage={currentPage}
              pageCount={data?.meta?.pagination?.pageCount ?? 0}
              onClick={(page: number) => {
                setCurrentPage(page)
              }}
            />
          </div>
        ) : null}
      </Panel>
      {(modalVisible || editModal !== null) && (
        <ModalCollection
          visible={true}
          onClose={closeModal}
          onSubmit={refresh}
          defaultValues={
            editModal
              ? {
                  title: editModal?.attributes.title ?? '',
                  description: editModal?.attributes.description ?? '',
                }
              : undefined
          }
          id={editModal ? editModal.id : undefined}
        />
      )}
    </div>
  )
}

export default AppCollectionsPage
