import React from 'react'

type Props = {
  h?: string
}

const Spacer = (props: Props) => {
  return <div className={`${props.h ? props.h : 'h-2'} w-full `} />
}

export default Spacer
