import Icon, { IconTypes } from './Icon'
import { PaginationButton } from './Pagination'

export interface Props {
  onClick: Function
  currentPage: number
  nextPage?: boolean
}

const PaginationUsers = (props: Props) => {
  const chevronStyle = 'rounded-full p-1 w-8 aspect-square hover:brightness-90'

  const pageDown = () => {
    if (props?.currentPage === 1) {
      return
    } else {
      props.onClick(props?.currentPage - 1)
    }
  }

  const pageUp = () => {
    if (props.nextPage) {
      props.onClick(props?.currentPage + 1)
    }
  }

  return (
    <div className="flex items-start gap-4">
      <button
        className={`${chevronStyle} ${
          props?.currentPage === 1 ? 'opacity-30' : null
        }`}
        onClick={pageDown}
      >
        <Icon type={IconTypes.arrow} />
      </button>

      <PaginationButton
        value={props.currentPage}
        onClick={(page: number) => {
          props.onClick(page)
        }}
        active
      />

      <button
        className={`${chevronStyle} rotate-180 ${
          props.nextPage ? 'null' : 'opacity-30'
        }`}
        onClick={pageUp}
      >
        <Icon type={IconTypes.arrow} />
      </button>
    </div>
  )
}

export default PaginationUsers
