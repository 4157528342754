import { sortTypes } from '../types/DropdownTypes'
import { baseAPI, apiPagination } from './base'
import { apiSingleVideo } from './videos'

export interface postNewCollection {
  data: newCollection
}

export interface newCollection {
  title: string
  description: string
}

export interface putCollection {
  title: string
  description: string
  updatedAt?: string
}

export interface apiSingleCollection {
  id: number
  attributes: {
    title: string
    description: string
    createdAt: string
    updatedAt: string
    publishedAt: string
  }
}

export interface getCollectionsType {
  data: apiSingleCollection[]
  meta: {
    pagination: apiPagination
  }
  error?: boolean
}

export interface getCollectionsFull {
  data: CollectionsFull[]
  meta: {
    pagination: apiPagination
  }
  error?: boolean
}

export interface CollectionsFull {
  id: number
  attributes: {
    title: string
    description: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    videos: {
      data: apiSingleVideo[]
    }
  }
}

export const collectionsAPI = {
  async getCollections(): Promise<getCollectionsType> {
    return await baseAPI.get('/collections?pagination[pageSize]=999')
  },

  async getCollectionsFull(
    page: number,
    sort?: sortTypes
  ): Promise<getCollectionsFull> {
    return await baseAPI.get(
      '/collections?populate=*&pagination[pageSize]=10',
      page,
      sort
    )
  },

  async getFindCollections(
    search: string,
    page: number,
    sort?: sortTypes
  ): Promise<getCollectionsFull> {
    return await baseAPI.search('/collections', 'Title', page, 10, search, sort)
  },

  async postNewCollection(payload: newCollection) {
    return await baseAPI.post('/collections', {
      data: payload,
    })
  },

  async putCollection(id: number, payload: putCollection) {
    return await baseAPI.put('/collections', id, {
      data: payload,
    })
  },
}

export default collectionsAPI
