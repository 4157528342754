import { KeyboardEvent } from 'react'
import Icon, { IconTypes } from './Icon'

type Props = {
  value: string
  onChange: Function
  placeholder?: string
  onEnter?: Function
  onCancel?: Function
}

const SearchBar = (props: Props) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (props.onEnter) {
        props.onEnter()
      }
    }
  }

  return (
    <div className="w-full flex bg-bgdark justify-between items-center pr-1 pl-2 rounded border border-bgdark focus-within:bg-black focus-within:border-primary transition">
      <div className="flex items-center w-full mx-2 py-[2px]">
        <Icon type={IconTypes.search} />

        <input
          type="text"
          className="p-2 text-sm placeholder-gray-400 w-full focus:outline-none appearance-none bg-bgdark focus:bg-black focus:text-white autofill:bg-black"
          onChange={(text) => props.onChange(text.target.value)}
          placeholder={props.placeholder ? props.placeholder : 'Search'}
          value={props.value}
          onKeyUp={props.onEnter ? handleKeyDown : undefined}
          autoComplete="off"
        />
      </div>

      <div
        className={`border-2 rounded-full p-1.5 hover:bg-primary transition duration-500 cursor-pointer ${
          props.value !== '' ? 'opacity-100' : 'opacity-5'
        }`}
        onClick={props.onCancel ? () => props.onCancel?.() : undefined}
      >
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.640004 7.35999C0.790004 7.50998 0.980004 7.57998 1.17 7.57998C1.36 7.57998 1.56 7.50998 1.7 7.35999L4 5.05999L6.3 7.35999C6.45 7.50998 6.64 7.57998 6.83 7.57998C7.02 7.57998 7.21 7.50998 7.36 7.35999C7.65 7.06998 7.65 6.58999 7.36 6.29999L5.06 3.99999L7.36 1.69998C7.65 1.40998 7.65 0.929986 7.36 0.639986C7.07 0.349985 6.59 0.349985 6.3 0.639986L4 2.93999L1.7 0.639986C1.41 0.349985 0.930004 0.349985 0.640004 0.639986C0.500524 0.781123 0.422302 0.971555 0.422302 1.16998C0.422302 1.36842 0.500524 1.55885 0.640004 1.69998L2.94 3.99999L0.640004 6.29999C0.500524 6.44112 0.422302 6.63155 0.422302 6.82998C0.422302 7.02842 0.500524 7.21885 0.640004 7.35999Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  )
}

export default SearchBar
