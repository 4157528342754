import React from 'react'
import Icon, { IconTypes } from './Icon'

type Props = {
  title?: string
  subtitle?: string
  onMenu: Function
  img?: string
  featured?: boolean
  isHCP: Boolean
}

const VideoTile = (props: Props) => {
  return (
    <div className="relative overflow-hidden rounded-xl">
      {props.featured && (
        <div className="absolute top-2 right-2 z-10 bg-[#7B78FF] text-textdark px-2.5 py-1.5 uppercase text-2xs font-semibold flex gap-1 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M7.03658 0.866507L8.09247 2.99572C8.23645 3.29212 8.62041 3.57642 8.94437 3.63086L10.8582 3.95145C12.082 4.15712 12.37 5.05236 11.4881 5.9355L10.0003 7.43563C9.74828 7.68968 9.6103 8.17964 9.68829 8.53048L10.1142 10.3875C10.4502 11.8574 9.67629 12.426 8.38643 11.6578L6.59263 10.5871C6.26867 10.3935 5.73473 10.3935 5.40476 10.5871L3.61096 11.6578C2.3271 12.426 1.54719 11.8513 1.88315 10.3875L2.3091 8.53048C2.3871 8.17964 2.24911 7.68968 1.99714 7.43563L0.509303 5.9355C-0.3666 5.05236 -0.084631 4.15712 1.13923 3.95145L3.05302 3.63086C3.37099 3.57642 3.75494 3.29212 3.89893 2.99572L4.95481 0.866507C5.53075 -0.288836 6.46664 -0.288836 7.03658 0.866507Z"
              fill="black"
            />
          </svg>
          <p className="">Featured</p>
        </div>
      )}

      <div className="w-full rounded-xl flex flex-col bg-bgdark overflow-hidden brightness-110">
        <div className="bg-bgdark w-full h-36 rounded-xl overflow-clip ">
          {/* PICTURE HERE */}
          {props.img ? (
            <img src={props.img} className="" alt="" />
          ) : (
            <img src="/placeholder.jpg" className="" alt="" />
          )}
        </div>
        <div className="w-full py-1 text-left pl-4 flex justify-between">
          <div className="w-full">
            <p className="text-xs h-8 text-ellipsis mr-2 overflow-clip line-clamp-2">
              {props.title}
            </p>
            <p className="text-2xs uppercase tracking-[1.5px] opacity-30">
              {props.subtitle}
            </p>
          </div>
          {props.isHCP && (
            <div className="mr-2 px-2 h-8 my-auto flex justify-center items-center bg-primary rounded-lg">
              <p className="text-xs">HCP</p>
            </div>
          )}
          <button
            className="px-3"
            onClick={() => {
              props.onMenu()
            }}
          >
            <Icon type={IconTypes.miniburger}></Icon>
          </button>
        </div>
      </div>
    </div>
  )
}

export default VideoTile
