import React from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  children?: React.ReactNode
  title?: string
  onClick?: Function
  link: string
  selected?: boolean
}

const NavButton = (props: Props) => {
  return (
    <>
      <NavLink
        to={props.link}
        className={({ isActive, isPending }) =>
          isPending
            ? 'bg-red-500'
            : isActive
            ? 'border-gray-400 w-32 py-2 hover:border-primary border-b-4 text-textlight text-center transition'
            : 'w-32 py-2 hover:border-primary border-surface border-b-4 text-textlight text-center transition duration-500'
        }
      >
        <p className="">
          {props.children}
          {props.title}
        </p>
      </NavLink>
    </>
  )
}

export default NavButton
