import React from 'react'

type Props = {
  children?: React.ReactNode
  type?: 'Top' | 'top' | 'mid' | 'Mid' | 'Bottom' | 'bottom'
  slim?: boolean
}

const Panel = (props: Props) => {
  const getType = () => {
    // Change the border radius based on props.type
    switch (props.type?.toLocaleLowerCase()) {
      case 'top':
        return 'rounded-t-md'
      case 'mid':
        return
      case 'bottom':
        return 'rounded-b-md'
      default:
        return 'rounded-md'
    }
  }

  return (
    <div
      className={
        `w-full py-8 bg-surface ${props.slim ? ' px-4 ' : ' px-8 '}` + getType()
      }
    >
      {/* <div className="w-full h-full border"> */}
      {props.children}
      {/* </div> */}
    </div>
  )
}

export default Panel
