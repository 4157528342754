import Toast from 'react-hot-toast'
import Panel from './Panel'
import Modal from './Modal'
import Button from './Button'
import { AddUserSchema, addUserSchema } from '../utils/schema'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormInput from './FormInput'
import FormError from './FormError'
import UsersAPI, { newUserType, putUserType } from '../api/users'
import { useEffect, useState } from 'react'
import Dropdown, { DropdownOption } from './Dropdown'
import { HCPStatus, USER_HCP_STATUS } from '../api/login'
import { useAuthContext } from '../api/AuthContext'

export interface Props {
  visible: boolean
  onClose: Function
  onSubmit: Function
  defaultValues?: {
    firstname?: string
    lastname?: string
    email?: string
    role?: number | null
    password?: string | null
    passwordConfirm?: string | null
    HCP?: HCPStatus
  }
  id?: number
  roleOptions?: {
    label: string
    value: number
  }[]
}

const parseDropdownDefaultHCPValue = (status?: HCPStatus | null) => {
  switch (status) {
    case USER_HCP_STATUS.PENDING:
      return {
        value: USER_HCP_STATUS.PENDING,
        label: 'Pending',
      }
    case USER_HCP_STATUS.CONFIRMED:
      return { value: USER_HCP_STATUS.CONFIRMED, label: 'Confirmed' }
    default:
      return {
        value: USER_HCP_STATUS.FALSE,
        label: 'False',
      }
  }
}

const ModalUser = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AddUserSchema>({
    defaultValues: props.defaultValues ?? {
      firstname: undefined,
      lastname: undefined,
      email: undefined,
      password: undefined,
      passwordConfirm: undefined,
      role: undefined,
      HCP: 'FALSE',
    },
    resolver: zodResolver(addUserSchema),
  })

  console.log('def HCP from modal', props.defaultValues?.HCP)

  useEffect(() => {
    if (props.defaultValues) {
      console.log('resetting')
      reset(props.defaultValues)
    }
  }, [props.defaultValues])

  const handleOnSubmit: SubmitHandler<AddUserSchema> = async (data) => {
    setLoading(true)
    if (props.defaultValues && props.id) {
      let payload: putUserType = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        HCP: data.HCP,
      }

      if (typeof data.password === 'string') {
        payload = { ...payload, password: data.password }
      }

      try {
        await UsersAPI.putUser(props.id, payload)
        Toast.success(`User ${data.email} updated`)
        setLoading(false)
        reset()
        props.onSubmit()
      } catch (error) {
        // @ts-ignore
        Toast.error(error?.message ?? 'Something went wrong!')
        setLoading(false)
      }
    } else {
      const payload: newUserType = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        password: data.password as string,
        role: data.role as number,
        HCP: data.HCP,
      }

      try {
        await UsersAPI.postNewUser(payload)
        Toast.success(`User ${data.email} created`)
        setLoading(false)
        reset()
        props.onSubmit()
      } catch (error) {
        // @ts-ignore
        Toast.error(error?.message ?? 'Something went wrong!')
        setLoading(false)
      }
    }
  }

  const onCancel = () => {
    reset()
    props.onClose()
  }

  console.log({ errors })

  return (
    <Modal visible={props.visible} onClose={onCancel}>
      <Panel>
        <form
          className="flex flex-col items-center"
          onSubmit={handleSubmit(handleOnSubmit)}
          noValidate
        >
          {props.id ? (
            <>
              <p className="text-xl pb-2">Edit user</p>
              <p className="text-sm pb-2">Update user details</p>
            </>
          ) : (
            <>
              <p className="text-xl pb-2">Add user</p>
              <p className="text-sm pb-2">Enter user details</p>
            </>
          )}
          <div className="w-full flex flex-col gap-2 pt-4">
            <FormInput
              type="text"
              placeholder="First name"
              error={Boolean(errors.firstname)}
              {...register('firstname')}
            />
            {errors.firstname && (
              <FormError>{errors.firstname.message ?? 'Error'}</FormError>
            )}
            <FormInput
              type="text"
              placeholder="Last name"
              error={Boolean(errors.lastname)}
              {...register('lastname')}
            />
            {errors.lastname && (
              <FormError>{errors.lastname.message ?? 'Error'}</FormError>
            )}
            <FormInput
              type="email"
              placeholder="Email address"
              error={Boolean(errors.email)}
              {...register('email')}
            />
            {errors.email && (
              <FormError>{errors.email.message ?? 'Error'}</FormError>
            )}
            <div className="mt-4 -mb-1 flex justify-between items-end">
              {props.id ? (
                <>
                  <p className="text-sm opacity-60">Set new password</p>
                  <p className="text-xs opacity-60 mt-4 -mb-1">
                    Leave fields blank to preserve existing
                  </p>
                </>
              ) : (
                <p className="text-sm opacity-60">Create password</p>
              )}
            </div>
            <FormInput
              type="password"
              placeholder="Password"
              error={Boolean(errors.password)}
              {...register('password')}
            />
            {errors.password && (
              <FormError>{errors.password.message ?? 'Error'}</FormError>
            )}
            <p className="text-sm opacity-60 mt-1 -mb-1">Confirm password</p>
            <FormInput
              type="password"
              placeholder="Confirm password"
              error={Boolean(errors.passwordConfirm)}
              {...register('passwordConfirm')}
            />
            {errors.passwordConfirm && (
              <FormError>{errors.passwordConfirm.message ?? 'Error'}</FormError>
            )}
            <div className="flex flex-col justify-between gap-2 w-fit">
              <p className="text-sm opacity-60 mt-1 -mb-1">HCP status</p>
              <Controller
                control={control}
                name="HCP"
                render={({ field: { value, onChange } }) => {
                  console.log({ value })
                  return (
                    <Dropdown
                      options={[
                        {
                          value: USER_HCP_STATUS.FALSE,
                          label: 'False',
                        },
                        { value: USER_HCP_STATUS.PENDING, label: 'Pending' },
                        {
                          value: USER_HCP_STATUS.CONFIRMED,
                          label: 'Confirmed',
                        },
                      ]}
                      value={parseDropdownDefaultHCPValue(value)}
                      defaultValue={parseDropdownDefaultHCPValue(
                        props.defaultValues?.HCP
                      )}
                      onChange={(option: DropdownOption) => {
                        onChange(option.value)
                      }}
                      placeholder="Select status"
                      className="w-full"
                    />
                  )
                }}
              />
            </div>
            {errors.HCP && (
              <FormError>{errors.HCP.message ?? 'Error'}</FormError>
            )}
          </div>
          <div className="flex justify-between w-full pt-4">
            <Button tall onClick={onCancel}>
              Cancel
            </Button>
            <Button
              tall
              variant="filled"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              {props.id ? 'Update' : 'Add'}
            </Button>
          </div>
        </form>
      </Panel>
    </Modal>
  )
}

export default ModalUser
