import z from 'zod'
import { USER_HCP_STATUS } from '../api/login'

export const addCollectionSchema = z.object({
  title: z.string().min(1, 'Required'),
  description: z.string().min(1, 'Required'),
})

export type AddCollectionSchema = z.infer<typeof addCollectionSchema>

export const addVideoSchema = z.object({
  title: z.string().min(1, 'Required'),
  description: z.string().min(1, 'Required'),
  thumbnail: z
    .instanceof(File, { message: 'Required' })
    .or(z.string().min(1, 'Required')),
  featured: z.boolean(),
  category: z
    .object({
      label: z.string(),
      value: z.number().or(z.string()),
    })
    .optional(),
  file: z
    .instanceof(File, { message: 'Required' })
    .or(z.string().min(1, 'Required')),
  collections: z
    .object({
      label: z.string(),
      value: z.number().or(z.string()).optional(),
    })
    .optional(),
  keywords: z.string(),
  isHCP: z.boolean(),
})

export type AddVideoSchema = z.infer<typeof addVideoSchema>

export const addLivestreamSchema = z.object({
  title: z.string().min(1, 'Required'),
  description: z.string(),
  thumbnail: z
    .instanceof(File, { message: 'Required' })
    .or(z.string())
    .optional(),
  streamUrl: z.string().min(1, 'Required'),
  datetime: z.date(),
  category: z
    .object({
      label: z.string(),
      value: z.number().or(z.string()),
    })
    .optional(),
  tags: z.string().optional(),
  finished: z.boolean().optional(),
  isHCP: z.boolean(),
})

export type AddLivestreamSchema = z.infer<typeof addLivestreamSchema>

export const addUserSchema = z
  .object({
    firstname: z.string().min(1, 'Required'),
    lastname: z.string().min(1, 'Required'),
    email: z.string().email(),
    role: z.number().nullable(),
    HCP: z
      .enum([
        USER_HCP_STATUS.FALSE,
        USER_HCP_STATUS.PENDING,
        USER_HCP_STATUS.CONFIRMED,
      ])
      .nullable(),
    password: z
      .string()
      .min(8, 'Password must have at least 8 characters')
      .nullable(),
    passwordConfirm: z
      .string()
      .min(8, 'Password must have at least 8 characters')
      .nullable(),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: 'Passwords not match',
    path: ['passwordConfirm'],
  })

export type AddUserSchema = z.infer<typeof addUserSchema>

export const loginSchema = z.object({
  email: z.string().email().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
})

export type LoginSchema = z.infer<typeof loginSchema>
