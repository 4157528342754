import React from 'react'
import SideNavContainer from './SideNavContainer'
import Icon, { IconTypes } from './Icon'
import { NavLink } from 'react-router-dom'
import SideNavButton from './SideNavButton'

export enum UsersMenuOption {
  CmsUsers,
}

type Props = {
  children?: React.ReactNode
  onChange: Function
  selected: UsersMenuOption
}

const UsersMenu = (props: Props) => {
  return (
    <SideNavContainer>
      <div className="p-2 ">
        <Icon type={IconTypes.appmanager} />
        <p className="mt-4">App manager</p>
      </div>
      <NavLink
        to="/users"
        className={({ isActive, isPending }) =>
          isPending ? 'bg-red-500' : isActive ? 'bg-primary rounded-xl' : ''
        }
      >
        <SideNavButton
          icon={IconTypes.user}
          title="CMS users"
          onClick={() => props.onChange(UsersMenuOption.CmsUsers)}
        />
      </NavLink>
    </SideNavContainer>
  )
}

export default UsersMenu
