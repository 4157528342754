import React from 'react'

type Props = {
  onClick: Function
  value: boolean
}

const ToggleSwitch = (props: Props) => {
  return (
    <div
      className={`w-10 h-6 bg-bgdark rounded-full flex items-center transition-all px-1 py-1 relative border 
    ${props.value ? 'border-primary' : 'border-bgdark'}`}
      onClick={() => props.onClick()}
    >
      <div
        className={`w-4 h-4  rounded-full transition-all absolute 
        ${
          props.value ? '  translate-x-full bg-primary' : 'bg-white opacity-60'
        }`}
      ></div>
    </div>
  )
}

export default ToggleSwitch
