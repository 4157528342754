import Toast from 'react-hot-toast'
import Panel from './Panel'
import Modal from './Modal'
import Button from './Button'
import { AddLivestreamSchema, addLivestreamSchema } from '../utils/schema'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormInput from './FormInput'
import FormError from './FormError'
import InputFile from './InputFile'
import Spacer from './Spacer'
import DatePicker from './DatePicker'
import TimePicker from './TimePicker'
import livestreamsAPI from '../api/livestreams'
import { useState } from 'react'
import FormTextarea from './FormTextarea'
import Dropdown, { DropdownOption } from './Dropdown'
import ToggleSwitch from './ToggleSwitch'

export interface Props {
  visible: boolean
  onClose: Function
  onSubmit: Function
  defaultValues?: {
    title: string
    description: string
    thumbnail: File | string
    streamUrl: string
    datetime?: Date
    category: DropdownOption
    tags?: string
    finished?: boolean
    isHCP: boolean
  }
  id?: number
}

const ModalLivestream = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<AddLivestreamSchema>({
    defaultValues: props.defaultValues ?? {
      title: '',
      description: '',
      thumbnail: undefined,
      streamUrl: '',
      datetime: new Date(),
      tags: '',
      category: undefined,
      finished: false,
      isHCP: false,
    },
    resolver: zodResolver(addLivestreamSchema),
  })
  const datetime = watch('datetime')
  const handleOnSubmit: SubmitHandler<AddLivestreamSchema> = async (data) => {
    let payload = {
      title: data.title,
      description: data.description,
      stream: data.streamUrl,
      datetime: data.datetime.toISOString(),
      category: (data.category?.value as string) ?? 'Rec360',
      tags: data.tags ?? '',
      finished: data.finished,
      isHCP: data.isHCP,
    }
    setLoading(true)
    if (props.defaultValues && props.id) {
      try {
        await livestreamsAPI.putLivestream(
          props.id,
          payload,
          typeof data.thumbnail === 'string' ? undefined : data.thumbnail
        )
        Toast.success('Livestream updated')
        setLoading(false)
        reset()
        props.onSubmit()
      } catch (error) {
        // @ts-ignore
        Toast.error(error?.message ?? 'Something went wrong!')
        setLoading(false)
      }
    } else {
      try {
        await livestreamsAPI.postLivestream(
          payload,
          typeof data.thumbnail === 'string' ? undefined : data.thumbnail
        )
        Toast.success(`Stream ${data.title} scheduled`)
        setLoading(false)
        reset()
        props.onSubmit()
      } catch (error) {
        // @ts-ignore
        Toast.error(error?.message ?? 'Something went wrong!')
        setLoading(false)
      }
    }
  }

  const onCancel = () => {
    reset()
    props.onClose()
  }

  return (
    <Modal visible={props.visible} onClose={onCancel}>
      <Panel>
        <form
          className="flex flex-col items-center"
          onSubmit={handleSubmit(handleOnSubmit)}
          noValidate
        >
          {props.id ? (
            <>
              <p className="text-xl pb-2">Edit a live stream</p>
              <p className="text-sm">Update stream details</p>
            </>
          ) : (
            <>
              <p className="text-xl pb-2">Add a live stream</p>
              <p className="text-sm">Enter stream details</p>
            </>
          )}
          <div className="w-full flex flex-col gap-2 pt-4">
            <FormInput
              type="text"
              placeholder="Collection name"
              error={Boolean(errors.title)}
              {...register('title')}
            />
            {errors.title && (
              <FormError>{errors.title.message ?? 'Error'}</FormError>
            )}
            <FormTextarea
              placeholder="Enter description"
              error={Boolean(errors.description)}
              {...register('description')}
              rows={8}
            />
            {errors.description && (
              <FormError>{errors.description.message ?? 'Error'}</FormError>
            )}
            <Controller
              control={control}
              name="thumbnail"
              render={({ field: { value, onChange } }) => (
                <InputFile
                  handleFile={(file: File) => onChange(file)}
                  placeholder={
                    typeof value === 'string' ? value : 'Select thumbnail file'
                  }
                  buttonText={
                    typeof value === 'string' ? 'Replace file' : 'Select file'
                  }
                />
              )}
            />
            {errors.thumbnail && (
              <FormError>{errors.thumbnail.message ?? 'Error'}</FormError>
            )}
            <Spacer />
            <FormInput
              type="text"
              placeholder="Enter live stream URL (RTMP stream)"
              error={Boolean(errors.streamUrl)}
              {...register('streamUrl')}
            />
            {errors.streamUrl && (
              <FormError>{errors.streamUrl.message ?? 'Error'}</FormError>
            )}
            <div className="w-full flex gap-4">
              <Controller
                control={control}
                name="datetime"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    value={value}
                    onChange={(newDate: Date) => onChange(newDate)}
                  />
                )}
              />
              <Controller
                control={control}
                name="datetime"
                render={({ field: { value, onChange } }) => (
                  <TimePicker
                    value={value}
                    onChange={(newDate: Date) => onChange(newDate)}
                  />
                )}
              />
            </div>
            <Spacer />
            <p className="text-sm">Select category</p>
            <Controller
              control={control}
              name="category"
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  options={[
                    { value: 'Rec360', label: 'Rec 360°' },
                    { value: 'Live360', label: 'Live 360°' },
                    { value: 'Webinar', label: 'Webinar' },
                    { value: 'Other', label: 'Other' },
                  ]}
                  value={value}
                  onChange={(option: DropdownOption) => {
                    onChange(option)
                  }}
                  placeholder="Select category"
                  className="w-full"
                />
              )}
            />
            {errors.category && (
              <FormError>{errors.category.message ?? 'Error'}</FormError>
            )}
            <p className="text-sm">Keywords</p>

            <FormTextarea
              placeholder="Enter comma seperated search keywords"
              error={Boolean(errors.tags)}
              {...register('tags')}
            />
            {errors.tags && (
              <FormError>{errors.tags.message ?? 'Error'}</FormError>
            )}
          </div>

          <div className="w-full flex justify-between py-2">
            <p className="text-sm opacity-60">HCP exclusive content</p>
            <Controller
              control={control}
              name="isHCP"
              render={({ field: { value = false, onChange } }) => (
                <ToggleSwitch value={value} onClick={() => onChange(!value)} />
              )}
            />
          </div>

          {datetime < new Date() && (
            <div className="w-full flex justify-between py-2">
              <p className="text-sm opacity-60">Is this stream finished?</p>
              <Controller
                control={control}
                name="finished"
                render={({ field: { value = false, onChange } }) => (
                  <ToggleSwitch
                    value={value}
                    onClick={() => onChange(!value)}
                  />
                )}
              />
            </div>
          )}

          <div className="flex justify-between w-full pt-4">
            <Button tall onClick={onCancel}>
              Cancel
            </Button>
            <Button
              tall
              variant="filled"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              {props.id ? 'Update' : 'Add'}
            </Button>
          </div>
        </form>
      </Panel>
    </Modal>
  )
}

export default ModalLivestream
