import React from 'react'
import LoadingSpinner from './LoadingSpinner'

type Props = {
  title?: string
  onClick?: Function
  children?: React.ReactNode
  type?: 'button' | 'submit' | 'reset'
  variant?: 'filled' | 'outlined' | 'text' //Defaults to outlined
  tall?: boolean
  wide?: boolean
  /**defaults are w-32/52 and h-8/10 */
  className?: string
  disabled?: boolean
  loading?: boolean
}

const Button = (props: Props) => {
  const onButtonClick = () => {
    if (props.disabled) {
      return
    } else {
      props.onClick?.()
    }
  }

  const getDimensions = () => {
    if (props.wide) {
      if (props.tall) {
        //Tall and wide
        return ' w-52 h-10'
      } else {
        return ' w-52 h-8' // short and wide
      }
    } else {
      if (props.tall) {
        // Tall and slim
        return ' w-32 h-10'
      } else {
        // Short and slim
        return ' w-32 h-8'
      }
    }
  }

  return (
    <>
      <button
        type={props.type ?? 'button'}
        onClick={onButtonClick}
        disabled={props.disabled}
        className={
          props.variant === 'filled'
            ? `text-sm relative rounded-full flex items-center justify-center bg-primary ${
                props.disabled ? 'bg-gray-500' : 'bg-primary hover:bg-highlight'
              } transition-all text-textlight ${
                props.className ? props.className : null
              }` + getDimensions()
            : `relative text-xs flex items-center justify-center rounded-full border-2 hover:bg-primary transition-all text-textlight ${
                props.className ? props.className : null
              }` + getDimensions()
        }
      >
        {props.title}
        {props.children}
        {props.loading ? (
          <div className="absolute right-1">
            <LoadingSpinner />
          </div>
        ) : null}
      </button>
    </>
  )
}

export default Button
