import React from 'react'
import Icon, { IconTypes } from './Icon'
import { Link } from 'react-router-dom'

type Props = {
  children?: React.ReactNode
  icon?: IconTypes
  title?: string
  link: string
}

const DashboardButton = (props: Props) => {
  return (
    <Link to={props.link}>
      <button className=" h-52 w-64 bg-surface rounded-md flex flex-col items-center justify-end pb-6 gap-8 hover:bg-bluegrey transition-colors">
        <Icon type={props.icon} />
        <p>{props.title}</p>
        {props.children}
      </button>
    </Link>
  )
}

export default DashboardButton
