import { useState, useEffect } from 'react'
import TitlePanel from '../components/TitlePanel'
import Panel from '../components/Panel'
import SearchBar from '../components/SearchBar'
import Button from '../components/Button'
import Row, { RowType } from '../components/Row'
import Callout from '../components/Callout'
import UsersAPI, { singleUser } from '../api/users'
import Dropdown, { DropdownOption } from '../components/Dropdown'
import { userPageLimit } from '../api/users'
import { sortTypes } from '../types/DropdownTypes'
import PaginationUsers from '../components/PaginationUsers'
import { roleType } from '../api/users'
import ModalUser from '../components/ModalUser'

const CmsUsersPage = () => {
  const [search, setSearch] = useState<string>('')
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [data, setData] = useState<singleUser[]>([])
  const [editModal, setEditModal] = useState<singleUser | null>(null)
  const [roleData, setRoleData] = useState<roleType[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [sortMode, setSortMode] = useState<sortTypes>(sortTypes.void)
  const pageLimit = userPageLimit // The pagination method for users is different, uses start=## rather than page=#

  useEffect(() => {
    getUserRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (search) {
      searchForUsers()
    } else {
      if (currentPage === 1) {
        getUsers()
      } else {
        getUsers((currentPage - 1) * pageLimit)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortMode])

  useEffect(() => {
    if (search === '') getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const getUsers = async (startAt?: number) => {
    try {
      const fetchData = await UsersAPI.getUsers('admin', startAt, sortMode)
      setData(fetchData)
    } catch (error) {
      console.log(error)
    }
  }

  const searchForUsers = async () => {
    try {
      const fetchData = await UsersAPI.getFindUsers(search, 'admin', sortMode)
      setData(fetchData)
    } catch (error) {
      console.log(error)
    }
  }

  const getUserRoles = async () => {
    try {
      const fetchRoles = await UsersAPI.getUserRoles()
      setRoleData(fetchRoles)
    } catch (error) {
      console.log(error)
    }
  }

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
    setEditModal(null)
  }

  const refresh = () => {
    closeModal()
    getUsers()
    setCurrentPage(1)
  }

  const onSearchCancel = () => {
    setSearch('')
    setCurrentPage(1)
  }

  const onSearchEnter = () => {
    if (currentPage !== 1) {
      setCurrentPage(1)
    } else {
      searchForUsers()
    }
  }

  const roleId = roleData?.find((role) => role.type === 'admin')?.id

  return (
    <div className="w-full flex flex-col gap-2">
      <TitlePanel
        title="CMS users"
        onClick={openModal}
        buttonText="Add new admin"
      />

      <Panel type="mid">
        <Callout>Find a user</Callout>
        <div className="flex justify-between w-full items-center mt-1">
          <div className="w-full">
            <SearchBar
              value={search}
              onChange={(text: string) => setSearch(text)}
              placeholder="Search by name or email"
              onCancel={onSearchCancel}
              onEnter={onSearchEnter}
            />
          </div>
          <div className="ml-8">
            <Button variant="outlined" tall onClick={onSearchEnter}>
              Search
            </Button>
          </div>
        </div>
      </Panel>

      <Panel type="bottom">
        <div className="flex justify-between pb-8">
          <Callout>{data?.length ? data?.length : '0'} Users</Callout>
          <Dropdown
            clearable
            defaultValue=""
            placeholder="Sort by"
            className=""
            onChange={(option: DropdownOption) => {
              setSortMode(option?.value as sortTypes)
            }}
            options={[
              { value: sortTypes.FirstnameAsc, label: 'Firstname Ascending' },
              { value: sortTypes.FirstnameDesc, label: 'Firstname Descending' },
              { value: sortTypes.LastnameAsc, label: 'Lastname Ascending' },
              { value: sortTypes.LastnameDesc, label: 'Lastname Descending' },
              { value: sortTypes.EmailAsc, label: 'Email Ascending' },
              { value: sortTypes.EmailDesc, label: 'Email Descending' },
            ]}
          />
        </div>

        <div className="gap-3 flex flex-col">
          {data?.map((item) => (
            <Row
              type={RowType.user}
              title={item.firstname + ' ' + item.lastname}
              subtitle={item.email}
              key={item.id}
              onMenu={() => {
                setEditModal(item)
              }}
              onClick={() => setEditModal(item)}
            />
          ))}
        </div>

        <div className="w-full pt-10 flex justify-end">
          {data ? (
            <PaginationUsers
              currentPage={currentPage}
              onClick={(page: number) => {
                setCurrentPage(page)
              }}
              nextPage={data.length === pageLimit}
            />
          ) : null}
        </div>
      </Panel>

      {(modalVisible || editModal !== null) && (
        <ModalUser
          visible={true}
          onClose={closeModal}
          onSubmit={refresh}
          defaultValues={
            editModal
              ? {
                  firstname: editModal?.firstname,
                  lastname: editModal?.lastname,
                  email: editModal?.email,
                  role: null,
                  password: null,
                  passwordConfirm: null,
                }
              : {
                  role: roleId,
                }
          }
          id={editModal ? editModal.id : undefined}
        />
      )}
    </div>
  )
}

export default CmsUsersPage
