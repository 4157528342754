import moment from 'moment'
import { sortTypes } from '../types/DropdownTypes'
import { baseAPI, apiPagination, fileType } from './base'
import { apiSingleVideo, videoCategoryType } from './videos'

export interface getLivestreamsType {
  data: apiSingleLivestream[]
  meta: {
    pagination: apiPagination
  }
  error?: boolean
}
export interface apiSingleLivestream {
  id: number
  attributes: {
    title: string
    description: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    stream: string
    datetime: string
    tags: string
    video?: {
      data: apiSingleVideo
    }
    thumb?: fileType
    category: videoCategoryType
    finished: boolean
    isHCP: boolean
  }
}

export interface livestreamAttributes {
  title: string
  description: string
  stream: string
  datetime: string
  category: string
  tags: string
}

export const livestreamsAPI = {
  async getLivestreams(
    page: number,
    sort?: sortTypes
  ): Promise<getLivestreamsType> {
    return await baseAPI.get(
      '/livestreams?populate=*&pagination[pageSize]=10',
      page,
      sort
    )
  },

  async getLivestreamsCalendar(
    date: Date,
    sort?: sortTypes
  ): Promise<getLivestreamsType> {
    const startDate = moment(date).startOf('M').toISOString()
    const endDate = moment(date).endOf('M').toISOString()

    return await baseAPI.get(
      `/livestreams?populate=*&pagination[pageSize]=100&filters[datetime][$gte]=${startDate}&filters[datetime][$lte]=${endDate}`,
      1,
      sort
    )
  },

  async getSingleLivestream(id: number) {
    let response = await baseAPI.get(`/livestreams/${id}?populate=*`)
    return response
  },

  async getFindLivestreams(
    search: string,
    page: number,
    sort?: sortTypes
  ): Promise<getLivestreamsType> {
    return await baseAPI.search('/livestreams', 'title', page, 10, search, sort)
  },

  async postLivestream(payload: livestreamAttributes, thumb?: File) {
    let formData = new FormData()
    formData.append('data', JSON.stringify(payload))
    if (thumb) {
      formData.append('files.thumb', thumb)
    }

    return await baseAPI.upload('/livestreams', formData)
  },

  async putLivestream(id: number, payload: livestreamAttributes, thumb?: File) {
    let livestreamDetails = await this.getSingleLivestream(id)

    let formData = new FormData()
    formData.append('data', JSON.stringify(payload))

    if (thumb) {
      if (livestreamDetails?.data?.attributes?.thumb?.data) {
        console.log('Attempting delete thumb')
      }
      formData.append('files.thumb', thumb)
    }

    return await baseAPI.putUpload(id, '/livestreams', formData)
  },
}

export default livestreamsAPI
