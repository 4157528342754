import React, { useEffect, useRef } from 'react'

type Props = {
  onClick: Function
  className?: string
  children: React.ReactNode
}

const OutsideListener = (props: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: TouchEvent | MouseEvent) => {
      if (wrapperRef && !wrapperRef?.current?.contains(event.target as Node)) {
        //   alert("You clicked outside of me!");
        props.onClick()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={props.className} ref={wrapperRef}>
      {props.children}
    </div>
  )
}

export default OutsideListener
