import Toast from 'react-hot-toast'
import Panel from './Panel'
import Modal from './Modal'
import Button from './Button'
import collectionsAPI from '../api/collections'
import { AddCollectionSchema, addCollectionSchema } from '../utils/schema'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormInput from './FormInput'
import FormError from './FormError'
import { useState } from 'react'
import FormTextarea from './FormTextarea'

export interface Props {
  visible: boolean
  onClose: Function
  onSubmit: Function
  defaultValues?: {
    title: string
    description: string
  }
  id?: number
}

const ModalCollection = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddCollectionSchema>({
    defaultValues: props.defaultValues ?? {
      title: '',
      description: '',
    },
    resolver: zodResolver(addCollectionSchema),
  })

  const handleOnSubmit: SubmitHandler<AddCollectionSchema> = async (data) => {
    setLoading(true)
    if (props.defaultValues && props.id) {
      try {
        await collectionsAPI.putCollection(props.id, data)
        Toast.success('Collection updated')
        setLoading(false)
        reset()
        props.onSubmit()
      } catch (error) {
        // @ts-ignore
        Toast.error(error?.message ?? 'Something went wrong!')
        setLoading(false)
      }
    } else {
      try {
        await collectionsAPI.postNewCollection(data)
        Toast.success('Collection added')
        setLoading(false)
        reset()
        props.onSubmit()
      } catch (error) {
        // @ts-ignore
        Toast.error(error?.message ?? 'Something went wrong!')
        setLoading(false)
      }
    }
  }

  const onCancel = () => {
    reset()
    props.onClose()
  }

  return (
    <Modal visible={props.visible} onClose={onCancel}>
      <Panel>
        <form
          className="flex flex-col items-center"
          onSubmit={handleSubmit(handleOnSubmit)}
          noValidate
        >
          {props.id ? (
            <>
              <p className="text-xl pb-2">Edit collection</p>
              <p className="text-sm">Update collection details</p>
            </>
          ) : (
            <>
              <p className="text-xl pb-2">Add a new collection</p>
              <p className="text-sm">Create a new collection</p>
            </>
          )}
          <div className="w-full flex flex-col gap-2 pt-4">
            <FormInput
              type="text"
              placeholder="Collection name"
              error={Boolean(errors.title)}
              {...register('title')}
            />
            {errors.title && (
              <FormError>{errors.title.message ?? 'Error'}</FormError>
            )}
            <FormTextarea
              placeholder="Enter description"
              error={Boolean(errors.description)}
              {...register('description')}
            />
            {errors.description && (
              <FormError>{errors.description.message ?? 'Error'}</FormError>
            )}
          </div>
          <div className="flex justify-between w-full pt-4">
            <Button tall onClick={onCancel}>
              Cancel
            </Button>
            <Button
              tall
              variant="filled"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              {props.id ? 'Update' : 'Add'}
            </Button>
          </div>
        </form>
      </Panel>
    </Modal>
  )
}

export default ModalCollection
