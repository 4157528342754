import React, { useState } from 'react'
import { AuthContext } from './AuthContext'
// import { message } from "antd";
import { API, BEARER, removeToken, userObject } from './login'
import { useEffect } from 'react'
import { getToken } from './login'

//@ts-ignore
const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState<userObject>()
  const [isLoading, setIsLoading] = useState(false)

  const authToken = getToken()

  const fetchLoggedInUser = async (token: any) => {
    setIsLoading(true)
    // console.log(`${BEARER} ${JSON.stringify(token)}`)
    try {
      // Try rerefresh bearer token
      const response = await fetch(`${API}/users/me`, {
        headers: { Authorization: `${BEARER} ${token}` },
      })
      const data = await response.json()
      // console.log(data);
      if (data?.error?.status === 401) {
        // If refresh fails due to old token, return to login screen
        setUserData(undefined)
        removeToken()
      } else {
        setUserData(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUser = (user: userObject) => {
    setUserData(user)
  }

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken)
    }
  }, [authToken])

  return (
    <AuthContext.Provider
      //@ts-ignore
      value={{ user: userData, setUser: handleUser, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
