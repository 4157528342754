import { useState } from 'react'
import UsersMenu, { UsersMenuOption } from '../components/UsersMenu'
import Page from '../components/Page'
import { Outlet } from 'react-router-dom'

type Props = {}

const CmsUserManagerPage = (props: Props) => {
  const [menu, setMenu] = useState(UsersMenuOption.CmsUsers)

  const onChangeMenu = (menuOpt: UsersMenuOption) => {
    setMenu(menuOpt)
  }

  return (
    <Page>
      <UsersMenu selected={menu} onChange={onChangeMenu} />
      <Outlet />
    </Page>
  )
}

export default CmsUserManagerPage
