import React from 'react'
import SideNavContainer from './SideNavContainer'
import SideNavButton from './SideNavButton'
import Icon, { IconTypes } from './Icon'
import { NavLink } from 'react-router-dom'
export enum AppMenuOption {
  Collections,
  VideoAssets,
  LiveStreams,
  AppUsers,
}

type Props = {
  children?: React.ReactNode
  onChange: Function
  selected: AppMenuOption
}

const AppMenu = (props: Props) => {
  return (
    <>
      <SideNavContainer>
        <div className="p-2 ">
          <Icon type={IconTypes.appmanager} />
          <p className="mt-4">App manager</p>
        </div>
        <NavLink
          to="/app"
          end
          className={({ isActive, isPending }) =>
            isPending ? 'bg-red-500' : isActive ? 'bg-primary rounded-xl' : ''
          }
        >
          <SideNavButton
            icon={IconTypes.folder}
            title="Collections"
            onClick={() => props.onChange(AppMenuOption.Collections)}
          />
        </NavLink>
        <NavLink
          to="/app/videos"
          className={({ isActive, isPending }) =>
            isPending ? 'bg-red-500' : isActive ? 'bg-primary rounded-xl' : ''
          }
        >
          <SideNavButton
            icon={IconTypes.play}
            title="Video assets"
            onClick={() => props.onChange(AppMenuOption.VideoAssets)}
          />
        </NavLink>
        <NavLink
          to="/app/livestreams"
          className={({ isActive, isPending }) =>
            isPending ? 'bg-red-500' : isActive ? 'bg-primary rounded-xl' : ''
          }
        >
          <SideNavButton
            icon={IconTypes.calendar}
            title="Live Streams"
            onClick={() => props.onChange(AppMenuOption.LiveStreams)}
          />
        </NavLink>
        <NavLink
          to="/app/users"
          className={({ isActive, isPending }) =>
            isPending ? 'bg-red-500' : isActive ? 'bg-primary rounded-xl' : ''
          }
        >
          <SideNavButton
            icon={IconTypes.user}
            title="App users"
            onClick={() => props.onChange(AppMenuOption.AppUsers)}
          />
        </NavLink>
      </SideNavContainer>
    </>
  )
}

export default AppMenu
