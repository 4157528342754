import ReactSelect from 'react-select'

export interface DropdownOption {
  value?: string | number
  label: string
}

type Props = {
  value?: any
  defaultValue?: any
  className?: string
  placeholder?: string
  options: DropdownOption[]
  onChange: Function
  searchable?: boolean
  clearable?: boolean
}

const Dropdown = (props: Props) => {
  const colors = {
    primary: '#3B38CE',
    highlight: '#4a47d1',
    bluegrey: '#414061',
    surface: '#242431',
    bgdark: '#0E1015',
    textlight: '#ffffff',
    textdark: '#000000',
    placeholder: '#ffffff99',
  }

  return (
    <ReactSelect
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={(value) => props.onChange(value)}
      options={props.options}
      className={`w-52 text-textlight text-sm ${props.className}`}
      isSearchable={props.searchable}
      isClearable={props.clearable}
      placeholder={props.placeholder ? props.placeholder : null}
      maxMenuHeight={200}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? colors.primary : colors.bgdark,
          boxShadow: state.isFocused ? colors.primary : 'none',
          backgroundColor: colors.bgdark,
          paddingLeft: '4px',
          '&:hover': {
            borderColor: colors.primary,
          },
        }),
        valueContainer: (baseStyles, state) => ({
          //This is the text entry area
          ...baseStyles,
          color: 'red',
        }),
        input: (baseStyles, state) => ({
          // This is the right area with the chevron
          ...baseStyles,
          color: 'white',
        }),
        placeholder: (baseStyles, state) => ({
          // placeholder
          ...baseStyles,
          color: colors.placeholder,
        }),
        singleValue: (baseStyles, state) => ({
          // selected value
          ...baseStyles,
          color: 'white',
        }),
        indicatorsContainer: (baseStyles, state) => ({
          // This is the right area with the chevron
          ...baseStyles,
          cursor: 'pointer',
        }),
        indicatorSeparator: (baseStyles, state) => ({
          // This is the right area with the chevron
          ...baseStyles,
          opacity: 0,
        }),
        menu: (baseStyles, state) => ({
          // This is the main dropdown area
          ...baseStyles,
          backgroundColor: colors.bgdark,
          zIndex: 20,
        }),
        menuList: (baseStyles, state) => ({
          ...baseStyles,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isFocused ? colors.primary : colors.bgdark,
        }),
      }}
    />
  )
}

export default Dropdown
