import HorOuterMargins from './HorOuterMargins'
import NavButton from './NavButton'

const Nav = () => {
  return (
    <div className="w-full bg-surface">
      <HorOuterMargins>
        <div className=" flex gap-2">
          <NavButton link="/">Dashboard</NavButton>
          <NavButton link="/app">App Manager</NavButton>
          <NavButton link="/users">User Manager</NavButton>
        </div>
      </HorOuterMargins>
    </div>
  )
}

export default Nav
