// export const AVATAR_API = "https://ui-avatars.com/api";
export const API = process.env.REACT_APP_API_HOST + '/api'
export const AUTH_TOKEN = 'authToken'
export const BEARER = 'Bearer'

export const USER_HCP_STATUS = {
  FALSE: 'FALSE',
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
} as const

export type HCPStatus = (typeof USER_HCP_STATUS)[keyof typeof USER_HCP_STATUS]

// When logging in, this is the object in user
export interface userObject {
  blocked: boolean
  confirmed: boolean
  createdAt: string
  email: string
  id: number
  provider: 'local'
  updatedAt: string
  username: string
  firstname: string
  lastname: string
  admin: boolean
  HCP: HCPStatus
}

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN)
}

export const setToken = (token: any) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
}

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN)
}
