import React, { ChangeEvent } from 'react'

interface InputProps {
  type: 'text' | 'number' | 'email' | 'password'
  value?: string | number
  name: string
  placeholder?: string
  error?: boolean
  disabled?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const FormInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { error, ...rest } = props

    return (
      <div
        className={`w-full flex bg-bgdark justify-between items-center rounded border border-bgdark focus-within:bg-black focus-within:border-primary ${
          error ? 'border border-red-600' : null
        }`}
      >
        <div className="flex items-center w-full mx-2">
          <input
            ref={ref}
            className={`px-2 py-2.5 text-sm placeholder:text-placeholder w-full focus:outline-none appearance-none bg-bgdark focus:bg-black focus:text-white autofill:bg-black`}
            {...rest}
          />
        </div>
      </div>
    )
  }
)

export default FormInput
