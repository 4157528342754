import { Link, useNavigate } from 'react-router-dom'
import HorOuterMargins from './HorOuterMargins'
import { useAuthContext } from '../api/AuthContext'
import { removeToken, userObject } from '../api/login'
import Logo from './Logo'

const TopNav = () => {
  const { user, setUser } = useAuthContext()
  const navigate = useNavigate()

  const handleLogout = () => {
    removeToken()
    //@ts-ignore
    setUser(undefined)
    navigate('/', { replace: true })
  }

  const genBadge = (user: userObject) => {
    if (user.email) {
      return user.email.charAt(0)
    }
  }

  return (
    <div className="h-16 bg-surface">
      <HorOuterMargins>
        <div className="w-full h-full flex justify-between text-textlight pt-2">
          <div className="pt-2">
            <Logo />
          </div>
          <div className="flex items-center">
            {user && (
              <button
                className="mr-4 text-xs hover:bg-bgdark hover:bg-opacity-40 px-4 py-2 rounded-xl transition"
                onClick={handleLogout}
              >
                Logout
              </button>
            )}
            {user ? (
              <button className="bg-black h-8 w-8 rounded-full flex items-center justify-center hover:bg-bluegrey transition ml-2">
                <p className="font-semibold uppercase">{genBadge(user)}</p>
              </button>
            ) : (
              <Link to="login">
                <button className="mr-4 text-xs hover:bg-bgdark hover:bg-opacity-40 px-4 py-2 rounded-xl transition">
                  Sign In
                </button>
              </Link>
            )}
          </div>
        </div>
      </HorOuterMargins>
    </div>
  )
}

export default TopNav
