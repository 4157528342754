import { useState } from 'react'
import AppMenu, { AppMenuOption } from '../components/AppMenu'
import Page from '../components/Page'
import { Outlet } from 'react-router-dom'

const AppManagerPage = () => {
  const [menu, setMenu] = useState(AppMenuOption.Collections)

  const onChangeMenu = (menuOpt: AppMenuOption) => {
    setMenu(menuOpt)
  }

  return (
    <Page>
      <AppMenu selected={menu} onChange={onChangeMenu} />
      <Outlet />
    </Page>
  )
}

export default AppManagerPage
