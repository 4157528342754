import { useNavigate } from 'react-router-dom'
import Icon, { IconTypes } from './Icon'
import moment from 'moment'

export enum RowType {
  collection,
  user,
  livestream,
}

type Props = {
  title?: string
  subtitle?: string
  type?: RowType
  onClick?: Function
  onNavigate?: string
  onMenu?: Function
  renderBadge?: () => React.ReactNode
}


const Row = (props: Props) => {
  const navigate = useNavigate()

  let dateObject
  if (props.type === RowType.livestream) {
    // dateObject = this.props.subtitle;
    if (moment(props.subtitle).isValid()) {
      dateObject = moment(props.subtitle)
    }
  }

  const onClick = () => {
    if (props.onNavigate) {
      navigate(props.onNavigate)
    } else if (props.onClick) {
      props.onClick()
    } else return
  }

  return (
    <div className="relative">
      <div className="w-full border border-bluegrey rounded-xl flex justify-between items-center pr-2 hover:bg-bgdark transition">
        <button className="flex items-center w-full" onClick={onClick}>
          {props.type === RowType.collection ? (
            <div className="w-16 aspect-square flex justify-center items-center">
              <Icon type={IconTypes.folderfill} />
            </div>
          ) : null}

          {props.type === RowType.livestream ? (
            <div
              className={`w-16 aspect-square flex justify-center items-center rounded-xl flex-col ${
                dateObject?.isAfter(moment()) ? 'bg-primary' : 'bg-bluegrey'
              }`}
            >
              <p className="font-bold text-xs">{dateObject?.format('D')}</p>
              <p className="text-2xs uppercase">{dateObject?.format('MMM')}</p>
            </div>
          ) : null}

          {props.type === RowType.user ? (
            <div className="w-16 aspect-square flex justify-center items-center">
              <Icon type={IconTypes.user} />
            </div>
          ) : null}

          <div className="ml-4 text-left">
            <p className="text-sm">{props.title}</p>
            <p className="text-2xs uppercase tracking-[1.5px] opacity-30">
              {props.type === RowType.livestream
                ? moment(props.subtitle).format('DD MMM YY @ HH:mm Z')
                : props.subtitle}
            </p>
          </div>
        </button>
        {props.renderBadge ? props.renderBadge() : null}

        <button
          className="mr-2 px-4 py-2 rounded-full aspect-square hover:bg-black"
          onClick={() => (props.onMenu ? props.onMenu() : null)}
        >
          <Icon type={IconTypes.miniburger} />
        </button>
      </div>
      {/* <div className="flex items-center absolute right-2 top-1/2 -translate-y-1/2 z-0"> */}
      {/* </div> */}
    </div>
  )
}

export default Row
