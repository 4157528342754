import React from 'react'
import Panel from './Panel'

type Props = {
  children?: React.ReactNode
}

const SideNavContainer = (props: Props) => {
  return (
    <div className="w-1/3 xl:w-1/5">
      <Panel slim>
        <div className="w-full h-full  flex flex-col gap-4">
          {props.children}
        </div>
      </Panel>
    </div>
  )
}

export default SideNavContainer
