type Props = {
  value?: boolean
  onClick: Function
}

const Checkbox = (props: Props) => {
  return (
    <div
      className={`
        bg-bgdark 
        ${props.value ? 'fill-primary' : 'fill-bgdark '}
        border
        ${props.value ? 'border-primary' : 'border-bgdark'}
        hover:border-primary
        hover:brightness-200
        aspect-square
        flex 
        justify-center 
        items-center 
        rounded
        cursor-pointer
        h-6 w-6`}
      onClick={() => {
        props.onClick()
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        width="20"
        viewBox="0 -960 960 960"
      >
        <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
      </svg>
    </div>
  )
}

export default Checkbox
