import { createBrowserRouter } from 'react-router-dom'
import Home from './pages/Home'
import DashboardPage from './pages/DashboardPage'
import AppManagerPage from './pages/AppManagerPage'
import AppCollectionsPage from './pages/AppCollectionsPage'
import AppVideosPage from './pages/AppVideosPage'
import AppLivestreamsPage from './pages/AppLivestreamsPage'
import AppUsersPage from './pages/AppUsersPage'
import CmsUserManagerPage from './pages/CmsUserManagerPage'
import CmsUsersPage from './pages/CmsUsersPage'
import LoginPage from './pages/LoginPage'

export const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Home />,
      children: [
        {
          path: '',
          element: <DashboardPage />,
        },
        {
          path: 'app',
          element: <AppManagerPage />,
          children: [
            {
              path: '',
              element: <AppCollectionsPage />,
            },
            {
              path: 'collections',
              element: <AppCollectionsPage />,
            },
            {
              path: 'videos',
              element: <AppVideosPage />,
            },
            {
              path: 'livestreams',
              element: <AppLivestreamsPage />,
            },
            {
              path: 'users',
              element: <AppUsersPage />,
            },
          ],
        },
        {
          path: 'users',
          element: <CmsUserManagerPage />,
          children: [
            {
              path: '',
              element: <CmsUsersPage />,
            },
          ],
        },
        {
          path: 'login',
          element: <LoginPage />,
        },
      ],
    },
  ],
  {
    basename: '/',
  }
)
