export enum IconTypes {
  'dashapp',
  'dashusers',
  'appmanager',
  'folder',
  'play',
  'calendar',
  'user',
  'plus',
  'time',
  'miniburger',
  'search',
  'folderfill',
  'arrow',
  'delete',
  'burger',
}

type Props = {
  color?: string
  size?: number
  type?: IconTypes
}

const Icon = (props: Props) => {
  switch (props.type) {
    case IconTypes.dashapp:
      return (
        <svg
          width="48"
          height="64"
          viewBox="0 0 48 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M37.6667 0.416748H10.3333C4.66168 0.416748 0.0833435 4.99508 0.0833435 10.6667V65.3334C0.0833435 71.0051 4.66168 75.5834 10.3333 75.5834H37.6667C43.3383 75.5834 47.9167 71.0051 47.9167 65.3334V10.6667C47.9167 4.99508 43.3383 0.416748 37.6667 0.416748ZM41.0833 58.5001H6.91668V10.6667H41.0833V58.5001ZM30.8333 68.7501H17.1667V65.3334H30.8333V68.7501Z"
            fill={props.color ? props.color : '#3B38CE'}
          />
        </svg>
      )
    case IconTypes.dashusers:
      return (
        <svg
          width="85"
          height="55"
          viewBox="0 0 85 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M57.6667 23.7083C63.9608 23.7083 69.0038 18.6274 69.0038 12.3333C69.0038 6.03909 63.9608 0.958252 57.6667 0.958252C51.3725 0.958252 46.2917 6.03909 46.2917 12.3333C46.2917 18.6274 51.3725 23.7083 57.6667 23.7083ZM27.3333 23.7083C33.6275 23.7083 38.6704 18.6274 38.6704 12.3333C38.6704 6.03909 33.6275 0.958252 27.3333 0.958252C21.0392 0.958252 15.9583 6.03909 15.9583 12.3333C15.9583 18.6274 21.0392 23.7083 27.3333 23.7083ZM27.3333 31.2916C18.4988 31.2916 0.791672 35.7278 0.791672 44.5624V54.0416H53.875V44.5624C53.875 35.7278 36.1679 31.2916 27.3333 31.2916ZM57.6667 31.2916C56.5671 31.2916 55.3158 31.3674 53.9888 31.4812C58.3871 34.6662 61.4583 38.9507 61.4583 44.5624V54.0416H84.2083V44.5624C84.2083 35.7278 66.5013 31.2916 57.6667 31.2916Z"
            fill={props.color ? props.color : '#3B38CE'}
          />
        </svg>
      )
    case IconTypes.appmanager:
      return (
        <svg
          width="52"
          height="77"
          viewBox="0 0 52 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M49.1111 21V56C49.1111 70 46.2222 73.5 34.6667 73.5H17.3334C5.7778 73.5 2.88892 70 2.88892 56V21C2.88892 7 5.7778 3.5 17.3334 3.5H34.6667C46.2222 3.5 49.1111 7 49.1111 21Z"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.7778 15.75H20.2222"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.0001 61.46C27.1876 61.46 28.3266 60.988 29.1663 60.1478C30.0061 59.3077 30.4778 58.1682 30.4778 56.98C30.4778 55.7918 30.0061 54.6523 29.1663 53.8122C28.3266 52.972 27.1876 52.5 26.0001 52.5C24.8125 52.5 23.6735 52.972 22.8338 53.8122C21.994 54.6523 21.5223 55.7918 21.5223 56.98C21.5223 58.1682 21.994 59.3077 22.8338 60.1478C23.6735 60.988 24.8125 61.46 26.0001 61.46Z"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    case IconTypes.folder:
      return (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10V16C21 20 20 21 16 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H7.5C9 1 9.33 1.44 9.9 2.2L11.4 4.2C11.78 4.7 12 5 13 5H16C20 5 21 6 21 10Z"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
        </svg>
      )
    case IconTypes.play:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.09998 12V10.52C9.09998 8.60999 10.45 7.83999 12.1 8.78999L13.38 9.52999L14.66 10.27C16.31 11.22 16.31 12.78 14.66 13.73L13.38 14.47L12.1 15.21C10.45 16.16 9.09998 15.38 9.09998 13.48V12Z"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )

    case IconTypes.calendar:
      return (
        <svg
          width={props.size ? props.size : "24"}
          height={props.size ? props.size : "24"}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 2V5"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 2V5"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.5 9.08997H20.5"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.695 13.7H15.704"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.695 16.7H15.704"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.995 13.7H12.005"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.995 16.7H12.005"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.29401 13.7H8.30401"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.29401 16.7H8.30401"
            stroke={props.color ? props.color : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    case IconTypes.user:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 12.75C8.83 12.75 6.25 10.17 6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75ZM12 2.75C10.8736 2.75264 9.79417 3.20126 8.99771 3.99771C8.20126 4.79417 7.75264 5.87364 7.75 7C7.75264 8.12636 8.20126 9.20583 8.99771 10.0023C9.79417 10.7987 10.8736 11.2474 12 11.25C13.1264 11.2474 14.2058 10.7987 15.0023 10.0023C15.7987 9.20583 16.2474 8.12636 16.25 7C16.2474 5.87364 15.7987 4.79417 15.0023 3.99771C14.2058 3.20126 13.1264 2.75264 12 2.75Z"
            fill={props.color ? props.color : 'white'}
          />
          <path
            d="M20.59 22.75C20.18 22.75 19.84 22.41 19.84 22C19.84 18.55 16.32 15.75 12 15.75C7.67997 15.75 4.15997 18.55 4.15997 22C4.15997 22.41 3.81997 22.75 3.40997 22.75C2.99997 22.75 2.65997 22.41 2.65997 22C2.65997 17.73 6.84997 14.25 12 14.25C17.15 14.25 21.34 17.73 21.34 22C21.34 22.41 21 22.75 20.59 22.75Z"
            fill={props.color ? props.color : 'white'}
          />
        </svg>
      )
    case IconTypes.plus:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
            fill={props.color ? props.color : 'white'}
          />
          <path
            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
            fill={props.color ? props.color : 'white'}
          />
        </svg>
      )
    case IconTypes.time:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22.75C6.76 22.75 2.5 18.49 2.5 13.25C2.5 8.01 6.76 3.75 12 3.75C17.24 3.75 21.5 8.01 21.5 13.25C21.5 18.49 17.24 22.75 12 22.75ZM12 5.25C7.59 5.25 4 8.84 4 13.25C4 17.66 7.59 21.25 12 21.25C16.41 21.25 20 17.66 20 13.25C20 8.84 16.41 5.25 12 5.25Z"
            fill={props.color ? props.color : 'white'}
          />
          <path
            d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
            fill={props.color ? props.color : 'white'}
          />
          <path
            d="M15 2.75H9C8.59 2.75 8.25 2.41 8.25 2C8.25 1.59 8.59 1.25 9 1.25H15C15.41 1.25 15.75 1.59 15.75 2C15.75 2.41 15.41 2.75 15 2.75Z"
            fill={props.color ? props.color : 'white'}
          />
        </svg>
      )
    case IconTypes.miniburger:
      return (
        <svg
          width="4"
          height="16"
          viewBox="0 0 4 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
            fill={props.color ? props.color : 'white'}
          />
        </svg>
      )
    case IconTypes.search:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="green"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
            fill={props.color ? props.color : 'white'}
          />
          <path
            d="M22 22.751C21.81 22.751 21.62 22.681 21.47 22.531L19.47 20.531C19.3305 20.3899 19.2523 20.1995 19.2523 20.001C19.2523 19.8026 19.3305 19.6122 19.47 19.471C19.76 19.181 20.24 19.181 20.53 19.471L22.53 21.471C22.82 21.761 22.82 22.241 22.53 22.531C22.38 22.681 22.19 22.751 22 22.751Z"
            fill={props.color ? props.color : 'white'}
          />
        </svg>
      )
    case IconTypes.folderfill:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 9V15C20 19 19 20 15 20H5C1 20 0 19 0 15V5C0 1 1 0 5 0H6.5C8 0 8.33 0.44 8.9 1.2L10.4 3.2C10.78 3.7 11 4 12 4H15C19 4 20 5 20 9Z"
            fill={props.color ? props.color : '#3B38CE'}
          />
        </svg>
      )
    case IconTypes.arrow:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.05 19.918L8.52999 13.398C7.75999 12.628 7.75999 11.368 8.52999 10.598L15.05 4.078"
            stroke="white"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    case IconTypes.delete:
      return (
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33341 5.5V13.8333H2.66675V5.5H9.33341ZM8.08341 0.5H3.91675L3.08341 1.33333H0.166748V3H11.8334V1.33333H8.91675L8.08341 0.5ZM11.0001 3.83333H1.00008V13.8333C1.00008 14.75 1.75008 15.5 2.66675 15.5H9.33341C10.2501 15.5 11.0001 14.75 11.0001 13.8333V3.83333Z"
            fill="#E6E6E8"
          />
        </svg>
      )
    case IconTypes.burger:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="12"
          fill="none"
          viewBox="0 0 16 12"
        >
          <path
            fill="#fff"
            d="M15.345 1.5H.655C.297 1.5 0 1.16 0 .75S.297 0 .655 0h14.69c.358 0 .655.34.655.75s-.297.75-.655.75ZM15.345 6.5H.655C.297 6.5 0 6.16 0 5.75S.297 5 .655 5h14.69c.358 0 .655.34.655.75s-.297.75-.655.75ZM15.345 11.5H.655c-.358 0-.655-.34-.655-.75S.297 10 .655 10h14.69c.358 0 .655.34.655.75s-.297.75-.655.75Z"
          />
        </svg>
      )
    default:
      return <></>
  }
}

export default Icon
