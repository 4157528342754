import React from 'react'
import Icon, { IconTypes } from './Icon'

type Props = {
  children?: React.ReactNode
  title?: string
  icon?: IconTypes
  selected?: boolean
  onClick: Function
}

const SideNavButton = (props: Props) => {
  return (
    <button
      className={`w-full px-4 py-4 rounded-xl flex items-center hover:bg-black hover:bg-opacity-20 transition`}
      onClick={props.onClick.bind(this)}
    >
      <Icon type={props.icon} />
      <p className="text-left ml-2 text-sm">{props.title}</p>
    </button>
  )
}

export default SideNavButton
