import React from 'react'
import HorOuterMargins from './HorOuterMargins'

type Props = {
  children?: React.ReactNode
}

const Page = (props: Props) => {
  return (
    <div className="bg-bgdark h-full">
      <HorOuterMargins>
        <div className="flex gap-4 bg-bgdark w-full py-4 h-full">
          {props.children}
        </div>
      </HorOuterMargins>
    </div>
  )
}

export default Page
