import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import TitlePanel from '../components/TitlePanel'
import Panel from '../components/Panel'
import SearchBar from '../components/SearchBar'
import Button from '../components/Button'
import Callout from '../components/Callout'
import VideoTile from '../components/VideoTile'
import videosAPI, { apiSingleVideo, apiVideos } from '../api/videos'
import moment from 'moment'
import Dropdown, { DropdownOption } from '../components/Dropdown'
import collectionsAPI from '../api/collections'
import Pagination from '../components/Pagination'
import { sortTypes } from '../types/DropdownTypes'
import Checkbox from '../components/Checkbox'
import ModalVideoAsset from '../components/ModalVideoAsset'

type ActiveHCPFilter = 'TRUE' | 'FALSE' | 'ALL'

const parseFilterDropdownOption = (filter: ActiveHCPFilter) => {
  switch (filter) {
    case 'TRUE':
      return {
        value: 'TRUE',
        label: 'HCP exclusive content',
      }
    case 'FALSE':
      return {
        value: 'FALSE',
        label: 'Not HCP restricted content',
      }
    default:
      return {
        value: 'ALL',
        label: 'Show all',
      }
  }
}

const getFilteredAssetData = (
  livestreams: apiVideos | undefined,
  activeFilter: ActiveHCPFilter
) => {
  if (!livestreams?.data || activeFilter === 'ALL') return livestreams
  const parsedLivestreams = { ...livestreams }
  parsedLivestreams.data = livestreams.data.filter((item) => {
    let parsedFilter = activeFilter === 'TRUE'
    return !!item.attributes.isHCP === parsedFilter
  })

  return parsedLivestreams
}

const AppVideosPage = () => {
  const [searchParams, setSearchParams] = useSearchParams() //use searchParams.get("query") to get url query
  //@ts-ignore
  const [data, setData] = useState<apiVideos>({})
  //@ts-ignore
  const [filteredData, setFilteredData] = useState<apiVideos | undefined>({})
  const [search, setSearch] = useState<string>('')
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [collections, setCollections] = useState<
    { value: number; label: string }[]
  >([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortMode, setSortMode] = useState<sortTypes>(sortTypes.void)
  const [editModal, setEditModal] = useState<apiSingleVideo | null>(null)
  const [featuredMode, setFeaturedMode] = useState<boolean>(false)
  const [activeHCPFilter, setActiveHCPFilter] = useState<ActiveHCPFilter>('ALL')

  const collectionId = searchParams.get('collection')
  const currentCollection = collections.find(
    (collection) => collection.value === Number(collectionId)
  )

  useEffect(() => {
    loadCollectionsOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (search) {
      searchVideos()
    } else {
      loadVideos()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortMode, currentPage, featuredMode, collectionId])

  useEffect(() => {
    if (search === '') searchVideos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    const filteredVideoData = getFilteredAssetData(data, activeHCPFilter)
    setFilteredData(filteredVideoData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activeHCPFilter])

  const loadVideos = async (): Promise<void> => {
    try {
      const fetchData = await videosAPI.getVideos(
        currentPage,
        sortMode,
        featuredMode,
        collectionId ? Number(collectionId) : undefined
      )
      setData(fetchData)
    } catch (error) {
      console.log(error)
    }
  }

  const searchVideos = async (): Promise<void> => {
    try {
      const fetchData = await videosAPI.getFindVideos(
        search,
        currentPage,
        sortMode,
        featuredMode,
        collectionId ? Number(collectionId) : undefined
      )
      setData(fetchData)
    } catch (error) {
      console.log(error)
    }
  }

  /**This loads the collections options for the dropdown */
  const loadCollectionsOptions = async (): Promise<void> => {
    try {
      let collectionsArr: { value: number; label: string }[] = []
      const fetchData = await collectionsAPI.getCollections()
      // eslint-disable-next-line array-callback-return
      fetchData.data.map((item) => {
        collectionsArr.push({ value: item.id, label: item.attributes.title })
      })
      setCollections(collectionsArr)
    } catch (error) {
      console.log(error)
    }
  }

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setEditModal(null)
    setModalVisible(false)
  }

  const refresh = () => {
    closeModal()
    setCurrentPage(1)
    loadVideos()
  }

  const onSearchCancel = () => {
    setSearch('')
    setCurrentPage(1)
  }

  const onSearchEnter = () => {
    if (currentPage !== 1) {
      setCurrentPage(1)
    } else {
      searchVideos()
    }
  }

  const getCurrentCollectionTitle = () => {
    if (!currentCollection) return ''
    return '/ ' + currentCollection.label
  }

  return (
    <div className="w-full flex flex-col gap-2">
      <TitlePanel
        title={`Video Assets ${getCurrentCollectionTitle()}`}
        buttonText="Add new video"
        onClick={openModal}
      />
      <Panel type="mid">
        <Callout>Find assets</Callout>
        <div className="flex justify-between w-full items-center mt-1 flex-col">
          <div className="flex justify-between flex-col lg:flex-row gap-2 lg:gap-4 w-full">
            <div className="lg:w-3/5 xl:w-full">
              <SearchBar
                value={search}
                onChange={(text: string) => setSearch(text)}
                placeholder="Search videos"
                onEnter={onSearchEnter}
                onCancel={onSearchCancel}
              />
            </div>
            <div className="lg:w-1/2">
              <Dropdown
                clearable
                searchable
                value={currentCollection}
                placeholder="Collection"
                className="w-full"
                onChange={(item: { value: number; label: string } | null) => {
                  if (item !== null) {
                    setSearchParams(`?collection=${item.value}`)
                  } else {
                    setSearchParams('')
                  }
                  setCurrentPage(1)
                }}
                options={collections}
              />
            </div>
          </div>

          <div className="w-full flex justify-between items-center mt-4">
            <div className="flex gap-4 items-center">
              <Checkbox
                value={featuredMode}
                onClick={() => {
                  setFeaturedMode(!featuredMode)
                  setCurrentPage(1)
                }}
              />
              <p
                className="text-sm cursor-pointer"
                onClick={() => {
                  setFeaturedMode(!featuredMode)
                  setCurrentPage(1)
                }}
              >
                Show featured videos only
              </p>
            </div>
            <Button variant="outlined" tall onClick={onSearchEnter}>
              Search
            </Button>
          </div>
        </div>
      </Panel>

      <Panel type="bottom">
        <div className="flex justify-between pb-8">
          <Callout>
            {data?.meta?.pagination?.total
              ? data?.meta?.pagination?.total
              : '0'}{' '}
            results
          </Callout>
          <div className="flex gap-4  w-4/6 justify-end">
            <Dropdown
              placeholder="View"
              className="w-2/4"
              value={parseFilterDropdownOption(activeHCPFilter)}
              onChange={(option: DropdownOption) => {
                setActiveHCPFilter(option.value as ActiveHCPFilter)
              }}
              options={[
                { value: 'ALL', label: 'Show all' },
                { value: 'TRUE', label: 'HCP exclusive content' },
                { value: 'FALSE', label: 'Not HCP restricted content' },
              ]}
            />
            <Dropdown
              clearable
              searchable
              defaultValue=""
              placeholder="Sort by"
              className="w-2/4"
              onChange={(option: DropdownOption) => {
                setSortMode(option?.value as sortTypes)
              }}
              options={[
                { value: sortTypes.TitleAsc, label: 'Title Ascending' },
                { value: sortTypes.TitleDesc, label: 'Title Descending' },
                { value: sortTypes.DateAsc, label: 'Date Ascending' },
                { value: sortTypes.DateDesc, label: 'Date Descending' },
              ]}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {filteredData?.data?.map((item: apiSingleVideo) => (
            <VideoTile
              title={item.attributes.title}
              subtitle={moment(item.attributes.createdAt).format('DD/MM/YY')}
              key={item.id}
              onMenu={() => {
                setEditModal(item)
              }}
              img={item.attributes?.thumb?.data?.attributes?.url}
              featured={item?.attributes?.featured}
              isHCP={item.attributes.isHCP}
            />
          ))}
        </div>
        <div className="w-full pt-10 flex justify-end">
          {data?.meta?.pagination ? (
            <Pagination
              currentPage={currentPage}
              pageCount={data?.meta?.pagination?.pageCount ?? 0}
              onClick={(page: number) => {
                setCurrentPage(page)
              }}
            />
          ) : null}
        </div>
      </Panel>
      {(modalVisible || editModal !== null) && (
        <ModalVideoAsset
          visible={true}
          onClose={closeModal}
          onSubmit={refresh}
          collections={collections}
          defaultValues={
            editModal
              ? {
                  title: editModal?.attributes?.title ?? '',
                  description: editModal?.attributes?.description ?? '',
                  thumbnail:
                    editModal?.attributes?.thumb?.data?.attributes?.name ?? '',
                  featured: editModal?.attributes?.featured ?? false,
                  file:
                    editModal?.attributes?.video?.data?.attributes?.name ?? '',
                  category: {
                    value: editModal?.attributes?.category ?? '',
                    label: editModal?.attributes?.category ?? '',
                  },
                  collections: {
                    value:
                      editModal?.attributes?.collections?.data[0]?.id ??
                      undefined,
                    label:
                      editModal?.attributes?.collections?.data[0]?.attributes
                        ?.title ?? '',
                  },
                  //@ts-ignore
                  keywords: editModal?.attributes?.keywords ?? '',
                  isHCP: editModal.attributes.isHCP,
                }
              : undefined
          }
          id={editModal ? editModal.id : undefined}
        />
      )}
    </div>
  )
}

export default AppVideosPage
