import Icon, { IconTypes } from './Icon'
import '../assets/react-datepicker.css'
import RDatePicker from 'react-datepicker'

type Props = {
  value: Date
  onChange: Function
}

const DatePicker = (props: Props) => {
  return (
    <div className="w-full relative flex items-center bg-bgdark rounded border border-bgdark focus-within:border-primary">
      <div className="z-10 absolute pl-2">
        <Icon type={IconTypes.calendar} color="white" />
      </div>
      <RDatePicker
        dateFormat="dd/MM/yyyy"
        showIcon
        className="bg-bgdark rounded outline-none"
        selected={props.value}
        onChange={(date) => props.onChange(date)}
      />
    </div>
  )
}

export default DatePicker
