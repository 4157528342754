import React from 'react'

type Props = {
  children: React.ReactNode | string
}

const Callout = (props: Props) => {
  return <p className="text-xs uppercase tracking-[1.5px]">{props.children}</p>
}

export default Callout
