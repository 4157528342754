import React from 'react'

type Props = {
  children?: React.ReactNode
}

const HorOuterMargins = (props: Props) => {
  return <div className="mx-2 md:mx-8 lg:mx-16 xl:mx-24">{props.children}</div>
}

export default HorOuterMargins
