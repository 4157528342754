import { baseAPI } from './base'
import { sortTypes } from '../types/DropdownTypes'
import { HCPStatus } from './login'

export interface postNewUser {
  data: newUserType
}

export interface roleType {
  id: number
  name: string
  description: string
  type: string
  createdAt: string
  updatedAt: string
  nb_users?: number
}

export interface newUserType {
  password: string
  email: string
  firstname: string
  lastname: string
  role: number
  HCP: HCPStatus | null
}

export interface putUserType {
  email: string
  firstname: string
  lastname: string
  password?: string
  role?: number
  HCP: HCPStatus | null
}

export interface singleUser {
  id: number
  username: string
  email: string
  provider: 'local'
  confirmed: boolean
  blocked: boolean
  createdAt: string
  updatedAt: string
  firstname: string
  lastname: string
  admin: boolean
  role: roleType
  HCP: HCPStatus
}

export const userPageLimit = 25

export const UsersAPI = {
  async getUsers(
    role: string,
    startAt?: number,
    sort?: sortTypes
  ): Promise<singleUser[]> {
    return await baseAPI.get(
      `/users?filters[role][type]=${role}&populate=*&limit=${userPageLimit}${
        startAt ? `&start=${startAt}` : ''
      }`,
      undefined,
      sort
    )
  },

  async getFindUsers(
    search: string,
    role: string,
    sort?: sortTypes
  ): Promise<singleUser[]> {
    return await baseAPI.get(
      `/users?filters[$or][0][firstname][$containsi]=${search}&filters[$or][1][lastname][$containsi]=${search}&filters[$or][2][email][$containsi]=${search}&filters[role][type]=${role}&populate=*`,
      1,
      sort
    )
  },

  async postNewUser(payload: newUserType) {
    return await baseAPI.post('/users', payload)
  },

  async putUser(id: number, payload: putUserType) {
    return await baseAPI.put('/users', id, payload)
  },

  async getUserRoles(): Promise<roleType[]> {
    return await baseAPI.get('/roles')
  },
}

export default UsersAPI
