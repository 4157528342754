import { baseAPI, fileType } from './base'
import { apiPagination } from './base'
import { sortTypes } from '../types/DropdownTypes'

export interface apiVideos {
  data: apiSingleVideo[]
  meta: {
    pagination: apiPagination
  }
}

export interface apiSingleVideo {
  id: number
  attributes: {
    title: string
    description: string
    date: string
    views: number | null
    createdAt: string
    updatedAt: string
    publishedAt: string
    featured: boolean
    hidden: boolean | null
    collections?: {
      data: {
        attributes: {
          title: string
          description: string
        }
        id: number
      }[]
    }
    video: fileType
    thumb: fileType
    category: videoCategoryType
    isHCP: boolean
  }
}

export enum videoCategoryType {
  'Live360' = 'Live360',
  'Rec360' = 'Rec360',
  'Webinar' = 'Webinar',
  'Other' = 'Other',
}

export const videosAPI = {
  async getVideos(
    page?: number,
    sort?: sortTypes,
    featuredMode?: boolean,
    collectionId?: number
  ): Promise<apiVideos> {
    let response = await baseAPI.get(
      `/videos?populate=*&pagination[pageSize]=12${
        featuredMode ? '&filters[featured][$eq]=true' : ''
      }${collectionId ? `&filters[collections][id][$eq]=${collectionId}` : ''}`,
      page,
      sort
    )
    return response
  },

  async getSingleVideo(id: number) {
    let response = await baseAPI.get(`/videos/${id}?populate=*`)
    return response
  },

  async getFindVideos(
    search: string,
    page: number,
    sort?: sortTypes,
    featuredMode?: boolean,
    collectionId?: number
  ): Promise<apiVideos> {
    return await baseAPI.search(
      '/videos',
      'Title',
      page,
      12,
      search,
      sort,
      `${featuredMode ? '&filters[featured][$eq]=true' : ''}${
        collectionId ? `&filters[collections][id][$eq]=${collectionId}` : ''
      }`
    )
  },

  /**Posts a file and new videos db entry */
  async postVideo(payload: any, video: File, thumb?: File) {
    let formData = new FormData()
    formData.append('data', JSON.stringify(payload))
    formData.append('files.video', video)
    if (thumb) {
      formData.append('files.thumb', thumb)
    }

    return await baseAPI.upload('/videos', formData)
  },

  /**Amends an entry and possibly replaces files */
  async putVideo(id: number, payload: any, video?: File, thumb?: File) {
    let videoDetails = await this.getSingleVideo(id)
    // let error = null

    let formData = new FormData()
    formData.append('data', JSON.stringify(payload))
    if (video) {
      if (videoDetails?.data.attributes?.video?.data) {
        console.log('Attempting delete vid')
        // let deleteVid = await baseAPI.delete(
        //   videoDetails.data.attributes.video.data.id,
        //   '/upload/files'
        // )
      }
      formData.append('files.video', video)
    }
    if (thumb) {
      if (videoDetails?.data?.attributes?.thumb?.data) {
        console.log('Attempting delete thumb')

        // let deleteThumb = await baseAPI.delete(
        //   videoDetails.data.attributes.thumb.data.id,
        //   '/upload/files'
        // )
      }
      formData.append('files.thumb', thumb)
    }

    return await baseAPI.putUpload(id, '/videos', formData)
  },

  async deleteVideo(id: number) {
    let videoDetails = await this.getSingleVideo(id)
    let error = null
    if (videoDetails?.data.attributes?.video?.data) {
      console.log('Attempting delete vid')
      let deleteVid = await baseAPI.delete(
        videoDetails.data.attributes.video.data.id,
        '/upload/files'
      )
      if (deleteVid.error) {
        error = 'Failed to delete video'
      }
    }
    if (videoDetails?.data?.attributes?.thumb?.data) {
      console.log('Attempting delete thumb')

      let deleteThumb = await baseAPI.delete(
        videoDetails.data.attributes.thumb.data.id,
        '/upload/files'
      )
      if (deleteThumb.error) {
        error = 'Failed to delete thumbnail'
      }
    }

    if (error == null) {
      return await baseAPI.delete(id, '/videos')
    } else {
      return {
        data: [],
        error: true,
        errorMessage: error,
        meta: {},
      }
    }
  },
}

export default videosAPI
