import Icon, { IconTypes } from './Icon'

const getPagingRange = (
  current = 1,
  { min = 1, total = 20, length = 3 } = {}
) => {
  if (length > total) length = total

  let start = current - Math.floor(length / 2)
  start = Math.max(start, min)
  start = Math.min(start, min + total - length)

  return Array.from({ length: length }, (_, i) => start + i)
}

export interface PaginationButtonProps {
  value: number
  active?: boolean
  onClick: Function
}

export const PaginationButton = (props: PaginationButtonProps) => {
  const buttonStyle = 'rounded-full p-1 w-8 aspect-square hover:brightness-90'

  return (
    <button
      className={`${buttonStyle} ${props.active ? 'bg-primary' : null}`}
      onClick={() => {
        props.onClick(props.value)
      }}
    >
      <p>{props.value}</p>
    </button>
  )
}

type Props = {
  currentPage: number
  pageCount: number
  onClick: Function
}

const Pagination = (props: Props) => {
  const { currentPage, pageCount, onClick } = props
  const chevronStyle = 'rounded-full p-1 w-8 aspect-square hover:brightness-90'

  const onPrevPage = () => {
    if (currentPage <= 1) return
    onClick(currentPage - 1)
  }
  const onNextPage = () => {
    if (currentPage >= pageCount) return
    onClick(currentPage + 1)
  }
  const onSetPage = (pageIndex: number) => {
    onClick(pageIndex)
  }

  return (
    <div className="flex items-start gap-4">
      <button
        className={`${chevronStyle} ${
          props?.currentPage === 1 ? 'opacity-30' : null
        }`}
        onClick={onPrevPage}
      >
        <Icon type={IconTypes.arrow} />
      </button>
      {currentPage > 2 && pageCount > 4 && (
        <>
          <PaginationButton
            value={1}
            active={false}
            onClick={() => onSetPage(1)}
          />
          <span className="h-full flex items-end pb-1">...</span>
        </>
      )}
      {getPagingRange(currentPage, {
        total: pageCount,
        length: pageCount <= 4 ? 4 : 3,
      }).map((item) => (
        <PaginationButton
          key={item}
          value={item}
          active={item === currentPage}
          onClick={() => onSetPage(item)}
        />
      ))}
      {currentPage < pageCount - 1 && pageCount > 4 && (
        <>
          <span className="h-full flex items-end pb-1">...</span>
          <PaginationButton
            value={pageCount}
            active={false}
            onClick={() => onSetPage(pageCount)}
          />
        </>
      )}
      <button
        className={`${chevronStyle} rotate-180 ${
          props?.currentPage === props?.pageCount ? 'opacity-30' : null
        }`}
        onClick={onNextPage}
      >
        <Icon type={IconTypes.arrow} />
      </button>
    </div>
  )
}

export default Pagination
