import React from 'react'
import Panel from './Panel'
import Button from './Button'
import Icon, { IconTypes } from './Icon'

type Props = {
  children?: React.ReactNode
  title?: string
  onClick?: Function
  buttonText?: string
}

const TitlePanel = (props: Props) => {
  return (
    <Panel type="Top">
      <div className="flex justify-between">
        <p className="text-3xl font-semibold">{props.title}</p>
        <Button variant="filled" wide tall onClick={props.onClick?.bind(this)}>
          <div className="flex items-center justify-center pr-2">
            <Icon type={IconTypes.plus} />
            {props.buttonText}
          </div>
        </Button>
      </div>
    </Panel>
  )
}

export default TitlePanel
