import React, { ChangeEvent } from 'react'

type TextareaProps = {
  value?: string | number
  name: string
  placeholder?: string
  error?: boolean
  disabled?: boolean
  rows?: number
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

const FormTextarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const { error, ...rest } = props

    return (
      <div
        className={`w-full flex bg-bgdark justify-between items-center rounded border border-bgdark focus-within:bg-black focus-within:border-primary ${
          error ? 'border border-red-600' : null
        }`}
      >
        <div className="flex items-center w-full px-2">
          <textarea
            ref={ref}
            className=" resize-none p-2 text-sm placeholder:text-placeholder w-full focus:outline-none appearance-none bg-bgdark focus:bg-black focus:text-white"
            rows={4}
            {...rest}
          />
        </div>
      </div>
    )
  }
)

export default FormTextarea
