import { createContext, useContext } from 'react'
import { userObject } from './login'

export interface AuthType {
  user: undefined | userObject
  isLoading: boolean
  setUser: () => void
}

export const AuthContext = createContext<AuthType>({
  user: undefined,
  isLoading: false,
  setUser: () => {},
})

export const useAuthContext = () => useContext(AuthContext)
