import React from 'react'
import OutsideListener from './OutsideListener'

type Props = {
  children?: React.ReactNode
  visible: boolean
  onClose: Function
}

const Modal = (props: Props) => {
  return (
    <div
      className={`z-50 left-0 top-0 bg-black bg-opacity-50 backdrop-blur-sm w-screen h-screen flex justify-center items-center ${
        props.visible ? 'fixed' : 'hidden'
      }`}
    >
      <OutsideListener
        onClick={props.onClose}
        className="w-[90dvw] sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5"
      >
        {props.children}
      </OutsideListener>
    </div>
  )
}

export default Modal
