import ReactDatePicker from 'react-datepicker'
import Icon, { IconTypes } from './Icon'

type Props = {
  value: Date
  onChange: Function
}

const TimePicker = (props: Props) => {
  return (
    <div className="w-full relative flex items-center bg-bgdark rounded border border-bgdark focus-within:border-primary">
      <div className="z-10 absolute pl-2">
        <Icon type={IconTypes.time} color="white" />
      </div>
      <ReactDatePicker
        selected={props.value}
        onChange={(date) => props.onChange(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        className="bg-bgdark rounded py-1.5 pl-10 outline-none"
      />
    </div>
  )
}

export default TimePicker
