import { Toaster } from 'react-hot-toast'
import { useAuthContext } from '../api/AuthContext'
import TopNav from '../components/TopNav'
import Nav from '../components/Nav'
import { Outlet } from 'react-router-dom'
import LoginPage from './LoginPage'

const Home = () => {
  const { user } = useAuthContext()

  return (
    <div className="flex flex-col min-h-[calc(100dvh)] min-w-[940px]">
      <TopNav />
      {user ? (
        <>
          <Nav />
          <div className="text-textlight h-full">
            <Outlet />
          </div>
        </>
      ) : (
        <div className="text-textlight h-full">
          <LoginPage />
        </div>
      )}
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          // Default options for specific types
          success: {
            style: {
              background: '#414061',
            },
          },
          error: {
            style: {
              background: '#4f1919',
            },
          },
        }}
      />
    </div>
  )
}

export default Home
