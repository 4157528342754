import { getToken } from './login'
import { sortTypes } from '../types/DropdownTypes'

export interface apiBaseResponse {
  data: any[]
  meta: {
    pagination: apiPagination
  }
  error?: boolean
}

export type apiPagination = {
  page: number
  pageCount: number
  pageSize: number
  total: number
}

export interface fileType {
  data: {
    attributes: {
      createdAt: string
      ext: string
      height: string | number
      name: string
      size: number
      url: string
      width: string | number
    }
    id: number
  }
}

export const baseAPI = {
  sortLookup(sort?: sortTypes) {
    switch (sort) {
      case sortTypes.TitleAsc: {
        return '&sort=title:asc'
      }
      case sortTypes.TitleDesc: {
        return '&sort=title:desc'
      }
      case sortTypes.DateAsc: {
        return '&sort=createdAt:asc'
      }
      case sortTypes.DateDesc: {
        return '&sort=createdAt:desc'
      }
      case sortTypes.DatetimeAsc: {
        return '&sort=datetime:asc'
      }
      case sortTypes.DatetimeDesc: {
        return '&sort=datetime:desc'
      }
      case sortTypes.EntriesAsc: {
        return '&sort=videos:asc'
      }
      case sortTypes.EntriesDesc: {
        return '&sort=videos:desc'
      }
      case sortTypes.FirstnameAsc: {
        return '&sort=firstname:asc'
      }
      case sortTypes.FirstnameDesc: {
        return '&sort=firstname:desc'
      }
      case sortTypes.LastnameAsc: {
        return '&sort=lastname:asc'
      }
      case sortTypes.LastnameDesc: {
        return '&sort=lastname:desc'
      }
      case sortTypes.EmailAsc: {
        return '&sort=email:asc'
      }
      case sortTypes.EmailDesc: {
        return '&sort=email:desc'
      }
      default:
        return ''
    }
  },

  async search(
    endpoint: string,
    field: string,
    page: number,
    perPage: number,
    search: string,
    sort?: sortTypes,
    optionalFilters?: string
  ): Promise<any> {
    return await this.get(
      `${endpoint}?populate=*&pagination[page]=${page}&pagination[pageSize]=${perPage}&filters[${field}][$containsi]=${search}${
        sort ? this.sortLookup(sort) : ''
      }${optionalFilters ?? ''}`
    )
  },

  /**Example query: '[title][$eq]=Surgery&[tags][$containsi]=bone' */
  async filter(endpoint: string, queries: string): Promise<any> {
    return await this.get(`${endpoint}?populate=*&filters${queries}`)
  },

  async get(endpoint: string, page?: number, sort?: sortTypes): Promise<any> {
    let data = {
      data: [],
      error: true,
      meta: {},
    }
    // console.log(`FETCH-${process.env.REACT_APP_API_HOST}/api${endpoint}${page ? `&pagination[page]=${page}` : ""}${sort ? this.sortLookup(sort): ""}`)
    await fetch(
      `${process.env.REACT_APP_API_HOST}/api${endpoint}${
        page ? `&pagination[page]=${page}` : ''
      }${sort ? this.sortLookup(sort) : ''}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then(
        (response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('Request failed!') // Error logging
        },
        (networkError) => {
          console.log('The request failed - ' + networkError.message)
          return data
        }
      )
      .then((jsonResponse: any) => {
        // console.log(jsonResponse)
        if (jsonResponse.data?.length !== 0) {
          //? Got a proper response
          // console.log(jsonResponse)
          data = jsonResponse
        } else {
          console.log('Got a response but the array was empty')
        }
      })

    return data
  },

  async post(endpoint: string, object: any) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api${endpoint}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(object),
      }
    )
    const jsonResponse = await response.json()

    if (response.ok) {
      return jsonResponse
    }

    throw new Error(jsonResponse?.error?.message ?? 'Request failed!')
  },

  async upload(endpoint: string, object: any) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api${endpoint}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        body: object,
      }
    )
    if (response.ok) {
      const jsonResponse = await response.json()
      console.log(jsonResponse)
      return jsonResponse
    }

    throw new Error('Request failed!')
  },

  /**Basically the same as upload, but with less headers */
  async putUpload(id: number, endpoint: string, object: any) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api${endpoint}/${id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        body: object,
      }
    )
    if (response.ok) {
      const jsonResponse = await response.json()
      console.log(jsonResponse)
      return jsonResponse
    }

    throw new Error('Request failed!')
  },

  async put(endpoint: string, id: number, object: any) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api${endpoint}/${id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(object),
      }
    )

    const jsonResponse = await response.json()

    if (response.ok) {
      return jsonResponse
    }

    throw new Error(jsonResponse?.error?.message ?? 'Request failed!')
  },

  /**Basically the same as upload, but with less headers */
  async delete(id: number, endpoint: string) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api${endpoint}/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )

    const jsonResponse = await response.json()

    if (response.ok) {
      return jsonResponse
    }

    throw new Error(jsonResponse?.error?.message ?? 'Request failed!')
  },
}
