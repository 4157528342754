import React, { useState } from 'react'
import Button from './Button'
import { Util } from '../api/Util'

type Props = {
  placeholder?: string
  handleFile: Function
  buttonText?: string
}

const InputFile = (props: Props) => {
  const [file, setFile] = useState<File>()

  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  const handleClick = () => {
    hiddenFileInput.current?.click()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event?.target?.files?.[0]
    setFile(fileUploaded)
    props.handleFile(fileUploaded)
  }

  const getText = () => {
    if (file !== undefined && file?.size) {
      return file.name
    } else if (props.placeholder) {
      return props.placeholder
    } else {
      return 'Select file'
    }
  }

  return (
    <div className="w-full flex bg-bgdark justify-between items-center rounded border border-bgdark focus-within:bg-black focus-within:border-primary">
      <div className="flex items-center w-full mx-2">
        <div className="pl-2 text-sm text-placeholder w-full bg-bgdark flex justify-between items-center py-1">
          <p className={`${file?.size ? 'text-textlight' : ''}`}>{getText()}</p>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={hiddenFileInput}
            onChange={handleChange}
          />
          <p>{file?.size ? Util.bytesToMb(file.size) : null}</p>
          <Button variant="filled" tall onClick={handleClick}>
            {props.buttonText ? props.buttonText : 'Select file'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InputFile
