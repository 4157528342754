import { FC } from 'react'
import { HCPStatus } from '../api/login'

type Props = {
  variant: HCPStatus | null
  email?: string
}

const HCPBadge: FC<Props> = ({ variant, email }) => {
  if (variant === 'PENDING')
    return (
      <a href={`mailto:${email}`} className="w-fit">
        <div className=" py-1 px-2 flex flex-frow-1 justify-center items-center bg-amber-500 rounded-lg w-fit">
          <p className="text-sm whitespace-nowrap ">
            HCP (PENDING) - click to send email
          </p>
        </div>
      </a>
    )
  if (variant === 'CONFIRMED')
    return (
      <div className=" py-1 px-2 flex justify-center items-center bg-primary rounded-lg">
        <p className="text-sm">HCP</p>
      </div>
    )

  return null
}

export default HCPBadge
