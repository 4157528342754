import { useEffect, useState } from 'react'
import livestreamsAPI, {
  apiSingleLivestream,
  getLivestreamsType,
} from '../api/livestreams'
import TitlePanel from '../components/TitlePanel'
import Panel from '../components/Panel'
import SearchBar from '../components/SearchBar'
import Button from '../components/Button'
import Callout from '../components/Callout'
import Row, { RowType } from '../components/Row'
import ModalLivestream from '../components/ModalLivestream'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import '../assets/calendar.css'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/en-gb'
import Icon, { IconTypes } from '../components/Icon'
import { sortTypes } from '../types/DropdownTypes'
import Dropdown, { DropdownOption } from '../components/Dropdown'
import Pagination from '../components/Pagination'

const localizer = momentLocalizer(moment)

type ActiveHCPFilter = 'TRUE' | 'FALSE' | 'ALL'

const parseFilterDropdownOption = (filter: ActiveHCPFilter) => {
  switch (filter) {
    case 'TRUE':
      return {
        value: 'TRUE',
        label: 'HCP exclusive content',
      }
    case 'FALSE':
      return {
        value: 'FALSE',
        label: 'Not HCP restricted content',
      }
    default:
      return {
        value: 'ALL',
        label: 'Show all',
      }
  }
}

const getFilteredAssetData = (
  livestreams: getLivestreamsType | undefined,
  activeFilter: ActiveHCPFilter
) => {
  if (!livestreams?.data || activeFilter === 'ALL') return livestreams
  const parsedLivestreams = { ...livestreams }
  parsedLivestreams.data = livestreams.data.filter((item) => {
    let parsedFilter = activeFilter === 'TRUE'
    return !!item.attributes.isHCP === parsedFilter
  })

  return parsedLivestreams
}

const AppLivestreamsPage = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [editModal, setEditModal] = useState<apiSingleLivestream | null>(null)
  const [searchMode, setSearchMode] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentDate, setCurrentDate] = useState<Date>(
    moment(new Date()).startOf('M').toDate()
  )
  const [viewType, setViewType] = useState<'calendar' | 'list'>('list')
  const [sortMode, setSortMode] = useState<sortTypes>(sortTypes.DatetimeDesc)
  const [livestreams, setLivestreams] = useState<
    getLivestreamsType | undefined
  >(undefined)
  const [activeHCPFilter, setActiveHCPFilter] = useState<ActiveHCPFilter>('ALL')
  const [filteredLivestreams, setFilteredLivestreams] = useState<
    getLivestreamsType | undefined
  >(undefined)

  const pastStreams =
    filteredLivestreams?.data?.filter((item) =>
      moment().isSameOrAfter(item.attributes.datetime)
    ) ?? []
  const scheduledStreams =
    filteredLivestreams?.data?.filter((item) =>
      moment().isBefore(item.attributes.datetime)
    ) ?? []

  useEffect(() => {
    if (search) {
      searchLivestreams()
    } else {
      getLivestreams()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortMode, currentPage, viewType, currentDate])

  useEffect(() => {
    if (search === '') {
      searchLivestreams()
      setSearchMode(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchMode, viewType])

  useEffect(() => {
    if (!livestreams?.data) return
    const filteredLivestreamsData = getFilteredAssetData(
      livestreams,
      activeHCPFilter
    )
    setFilteredLivestreams(filteredLivestreamsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livestreams, activeHCPFilter])

  const getLivestreams = async () => {
    try {
      const futureFetch =
        viewType === 'list'
          ? await livestreamsAPI.getLivestreams(
              currentPage,
              sortMode ?? sortTypes.DatetimeDesc
            )
          : await livestreamsAPI.getLivestreamsCalendar(
              currentDate,
              sortMode ?? sortTypes.DatetimeDesc
            )
      setLivestreams(futureFetch)
    } catch (error) {
      console.log(error)
    }
  }

  const searchLivestreams = async () => {
    try {
      const fetchData = await livestreamsAPI.getFindLivestreams(
        search,
        currentPage,
        sortMode ?? sortTypes.DatetimeDesc
      )
      setLivestreams(fetchData)
    } catch (error) {
      console.log(error)
    }
  }

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
    setEditModal(null)
  }

  const refresh = () => {
    closeModal()
    getLivestreams()
    setCurrentPage(1)
  }

  const customDayPropGetter = (date: Date) => {
    if (date.getDay() === 6 || date.getDay() === 0)
      return {
        className: 'rbc-day-weekend',
      }
    else return {}
  }

  const handleCalendarStreamClick = (id: number) => {
    const item = livestreams?.data.find((stream) => stream.id === id) ?? null

    setEditModal(item)
  }

  const onSearchCancel = () => {
    setSearch('')
    setSearchMode(false)
    setCurrentPage(1)
  }

  const onSearchEnter = () => {
    if (currentPage !== 1) {
      setCurrentPage(1)
    } else {
      searchLivestreams()
    }
    setSearchMode(true)
  }

  return (
    <div className="w-full flex flex-col gap-2">
      <TitlePanel
        title="Live Streams"
        onClick={openModal}
        buttonText="Add new stream"
      />
      <Panel type="mid">
        <div className="flex justify-between w-full items-center mt-1">
          <div className="w-full">
            <SearchBar
              value={search}
              onChange={(text: string) => setSearch(text)}
              placeholder="Search livestreams"
              onCancel={onSearchCancel}
              onEnter={onSearchEnter}
            />
          </div>
          <div className="ml-8">
            <Button variant="outlined" tall onClick={onSearchEnter}>
              Search
            </Button>
          </div>
        </div>
      </Panel>

      <Panel type="bottom">
        <div className="flex gap-4 mb-8 justify-between">
          <div className="flex gap-4">
            <button
              type="button"
              className={`bg-black flex items-center justify-center w-[38px] h-[38px] rounded-md border  ${
                viewType === 'list' ? 'border-primary' : 'border-transparent'
              }`}
              onClick={() => {
                setViewType('list')
              }}
            >
              <Icon type={IconTypes.burger} color="white" />
            </button>
            <button
              type="button"
              onClick={() => {
                setViewType('calendar')
              }}
              className={`bg-black flex items-center justify-center w-[38px] h-[38px] rounded-md border ${
                viewType === 'calendar'
                  ? 'border-primary'
                  : 'border-transparent'
              }`}
            >
              <Icon type={IconTypes.calendar} color="white" size={20} />
            </button>
          </div>
          <div className="flex gap-4 w-4/6 justify-end">
            {viewType === 'calendar' && (
              <div className="flex gap-2">
                <button
                  type="button"
                  onClick={() => {
                    setCurrentDate((prevDate) =>
                      moment(prevDate).subtract(1, 'M').toDate()
                    )
                  }}
                  className={`bg-black flex items-center justify-center w-[38px] h-[38px] rounded-md`}
                >
                  <Icon type={IconTypes.arrow} color="white" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentDate((prevDate) =>
                      moment(prevDate).add(1, 'M').toDate()
                    )
                  }}
                  className={`bg-black flex items-center justify-center w-[38px] h-[38px] rounded-md`}
                >
                  <span className="rotate-180">
                    <Icon type={IconTypes.arrow} color="white" />
                  </span>
                </button>
              </div>
            )}

            <div
              className={
                viewType === 'list' ? `flex gap-4  w-5/6 justify-end` : ''
              }
            >
              {viewType === 'list' && (
                <Dropdown
                  placeholder="View"
                  className="w-2/4"
                  value={parseFilterDropdownOption(activeHCPFilter)}
                  onChange={(option: DropdownOption) => {
                    setActiveHCPFilter(option.value as ActiveHCPFilter)
                  }}
                  options={[
                    { value: 'ALL', label: 'Show all' },
                    { value: 'TRUE', label: 'HCP exclusive content' },
                    { value: 'FALSE', label: 'Not HCP restricted content' },
                  ]}
                />
              )}
              <Dropdown
                defaultValue=""
                placeholder="Sort by"
                className={viewType === 'list' ? `w-2/4` : ''}
                onChange={(val: DropdownOption) => {
                  setSortMode(val?.value as sortTypes)
                  if (viewType !== 'list') setViewType('list')
                }}
                options={[
                  { value: sortTypes.DatetimeAsc, label: 'Date Ascending' },
                  { value: sortTypes.DatetimeDesc, label: 'Date Descending' },
                ]}
                clearable
                searchable
              />
            </div>
          </div>
        </div>
        {viewType === 'calendar' && (
          <>
            <div className="flex justify-between text-xl font-bold">
              <div>{moment(currentDate).format('MMMM')}</div>
              <div className="opacity-30">
                {moment(currentDate).format('yyyy')}
              </div>
            </div>
            <Calendar
              date={currentDate}
              localizer={localizer}
              events={
                livestreams?.data.map((item) => ({
                  id: item.id,
                  title: item.attributes.title,
                  start: new Date(item.attributes.datetime),
                  end: new Date(item.attributes.datetime),
                })) ?? []
              }
              startAccessor="start"
              endAccessor="end"
              defaultView={Views.MONTH}
              views={['month']}
              dayPropGetter={customDayPropGetter}
              toolbar={false}
              onSelectEvent={(data) => {
                handleCalendarStreamClick(data.id)
                return
              }}
              onNavigate={(date) => {
                setCurrentDate(date)
              }}
              style={{
                height: '600px',
                marginBottom: '20px',
              }}
              popup
            />
          </>
        )}
        {viewType === 'list' && (
          <>
            {!searchMode ? (
              <div>
                {Boolean(scheduledStreams.length) && (
                  <>
                    <Callout>Scheduled streams</Callout>
                    <div className="gap-3 flex flex-col mt-4 mb-8">
                      {scheduledStreams.map((item) => (
                        <Row
                          type={RowType.livestream}
                          title={item.attributes.title}
                          subtitle={item.attributes.datetime}
                          key={item.id}
                          onMenu={() => setEditModal(item)}
                          onClick={() => setEditModal(item)}
                        />
                      ))}
                    </div>
                  </>
                )}
                {Boolean(pastStreams.length) && (
                  <>
                    <Callout>Past streams</Callout>
                    <div className="gap-3 flex flex-col mt-4 mb-8">
                      {pastStreams.map((item) => (
                        <Row
                          type={RowType.livestream}
                          title={item.attributes.title}
                          subtitle={item.attributes.datetime}
                          key={item.id}
                          onMenu={() => setEditModal(item)}
                          onClick={() => setEditModal(item)}
                          {...(item.attributes.isHCP && {
                            renderBadge: () => (
                              <div className="py-1 px-2 flex justify-center items-center bg-primary rounded-lg">
                                <p className="text-sm">HCP</p>
                              </div>
                            ),
                          })}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div>
                <Callout>Found streams</Callout>
                <div className="gap-3 flex flex-col mt-4 mb-8">
                  {filteredLivestreams?.data.map((item) => (
                    <Row
                      type={RowType.livestream}
                      title={item.attributes.title}
                      subtitle={item.attributes.datetime}
                      key={item.id}
                      onMenu={() => setEditModal(item)}
                      onClick={() => setEditModal(item)}
                    />
                  ))}
                </div>
              </div>
            )}
            {livestreams?.meta?.pagination ? (
              <div className="w-full pt-2 flex justify-end">
                <Pagination
                  currentPage={currentPage}
                  pageCount={livestreams?.meta?.pagination?.pageCount ?? 0}
                  onClick={(page: number) => {
                    setCurrentPage(page)
                  }}
                />
              </div>
            ) : null}
          </>
        )}
      </Panel>
      {(modalVisible || editModal !== null) && (
        <ModalLivestream
          visible={true}
          onClose={closeModal}
          onSubmit={refresh}
          defaultValues={
            editModal
              ? {
                  title: editModal?.attributes?.title ?? '',
                  description: editModal?.attributes?.description ?? '',
                  thumbnail:
                    editModal?.attributes?.thumb?.data?.attributes?.name ?? '',
                  streamUrl: editModal?.attributes?.stream ?? '',
                  datetime: editModal?.attributes?.datetime
                    ? new Date(editModal?.attributes?.datetime)
                    : undefined,
                  tags: editModal?.attributes?.tags ?? '',
                  category: {
                    value: editModal?.attributes?.category ?? '',
                    label: editModal?.attributes?.category ?? '',
                  },
                  finished: editModal.attributes.finished,
                  isHCP: editModal.attributes.isHCP,
                }
              : undefined
          }
          id={editModal ? editModal.id : undefined}
        />
      )}
    </div>
  )
}

export default AppLivestreamsPage
