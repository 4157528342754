import { useEffect, useState } from 'react'
import TitlePanel from '../components/TitlePanel'
import Panel from '../components/Panel'
import SearchBar from '../components/SearchBar'
import Button from '../components/Button'
import Dropdown, { DropdownOption } from '../components/Dropdown'
import Row, { RowType } from '../components/Row'
import Callout from '../components/Callout'
import PaginationUsers from '../components/PaginationUsers'
import UsersAPI, { roleType, singleUser } from '../api/users'
import { userPageLimit } from '../api/users'
import { sortTypes } from '../types/DropdownTypes'
import ModalUser from '../components/ModalUser'
import HCPBadge from '../components/HCPBadge'
import { HCPStatus, USER_HCP_STATUS } from '../api/login'

type ActiveHCPFilter = HCPStatus | 'ALL'

const parseDropdownActiveHCPFilterValue = (filter: ActiveHCPFilter) => {
  switch (filter) {
    case USER_HCP_STATUS.FALSE:
      return {
        value: USER_HCP_STATUS.FALSE,
        label: 'No HCP status',
      }
    case USER_HCP_STATUS.PENDING:
      return {
        value: USER_HCP_STATUS.PENDING,
        label: 'Pending HCP status request',
      }
    case USER_HCP_STATUS.CONFIRMED:
      return { value: USER_HCP_STATUS.CONFIRMED, label: 'Confirmed HCP status' }
    default:
      return {
        value: 'ALL',
        label: 'Show all',
      }
  }
}

const getFilteredUserData = (
  data: singleUser[],
  activeFilter: ActiveHCPFilter
) => {
  if (activeFilter === 'ALL') return data
  return data.filter((item) => {
    //account for default null in api fields
    if (activeFilter === 'FALSE') return !item.HCP || item.HCP === activeFilter
    return item.HCP === activeFilter
  })
}

const AppUsersPage = () => {
  const [search, setSearch] = useState<string>('')
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [data, setData] = useState<singleUser[]>([])
  const [filteredData, setFilteredData] = useState<singleUser[]>([])
  const [editModal, setEditModal] = useState<singleUser | null>(null)
  const [roleData, setRoleData] = useState<roleType[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [sortMode, setSortMode] = useState<sortTypes>(sortTypes.void)
  const [activeHCPFilter, setActiveHCPFilter] = useState<ActiveHCPFilter>('ALL')

  const pageLimit = userPageLimit // The pagination method for users is different, uses start=## rather than page=#

  useEffect(() => {
    getUserRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (search) {
      searchForUsers()
    } else {
      if (currentPage === 1) {
        getUsers()
      } else {
        getUsers((currentPage - 1) * pageLimit)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortMode])

  useEffect(() => {
    if (search === '') getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    const filteredUserData = getFilteredUserData(data, activeHCPFilter)
    setFilteredData(filteredUserData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activeHCPFilter])

  const getUsers = async (startAt?: number) => {
    try {
      const fetchData = await UsersAPI.getUsers(
        'authenticated',
        startAt,
        sortMode
      )
      setData(fetchData)
    } catch (error) {
      console.log(error)
    }
  }

  const searchForUsers = async () => {
    try {
      const fetchData = await UsersAPI.getFindUsers(
        search,
        'authenticated',
        sortMode
      )
      setData(fetchData)
    } catch (error) {
      console.log(error)
    }
  }

  const getUserRoles = async () => {
    try {
      const fetchRoles = await UsersAPI.getUserRoles()
      setRoleData(fetchRoles)
    } catch (error) {
      console.log(error)
    }
  }

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setEditModal(null)
    setModalVisible(false)
  }

  const refresh = () => {
    closeModal()
    getUsers()
    setCurrentPage(1)
  }

  const onSearchCancel = () => {
    setSearch('')
    setCurrentPage(1)
  }

  const onSearchEnter = () => {
    if (currentPage !== 1) {
      setCurrentPage(1)
    } else {
      searchForUsers()
    }
  }

  const parseTitle = (item: any) => {
    const name: string[] = [item.firstname, item.lastname].filter(
      (value) => value?.length > 0
    )
    if (name.length === 0) {
      return item.email
    }
    return name.join(' ')
  }

  const renderUsers = () => {
    let rows: JSX.Element[] = []

    if (filteredData?.length) {
      filteredData?.map((item) =>
        rows.push(
          <Row
            type={RowType.user}
            title={parseTitle(item)}
            subtitle={item.email}
            key={item.id}
            onMenu={() => {
              setEditModal(item)
            }}
            onClick={() => setEditModal(item)}
            {...(item?.HCP &&
              item.HCP !== USER_HCP_STATUS.FALSE && {
                renderBadge: () => (
                  <HCPBadge variant={item.HCP} email={item.email} />
                ),
              })}
          />
        )
      )
    }

    return rows
  }

  const roleId = roleData?.find((role) => role.type === 'authenticated')?.id

  return (
    <div className="w-full flex flex-col gap-2">
      <TitlePanel
        title="App users"
        onClick={openModal}
        buttonText="Add new user"
      />

      <Panel type="mid">
        <Callout>Find a user</Callout>
        <div className="flex justify-between w-full items-center mt-1">
          <div className="w-full">
            <SearchBar
              value={search}
              onChange={(text: string) => setSearch(text)}
              placeholder="Search by name or email"
              onCancel={onSearchCancel}
              onEnter={onSearchEnter}
            />
          </div>
          <div className="ml-8">
            <Button variant="outlined" tall onClick={onSearchEnter}>
              Search
            </Button>
          </div>
        </div>
      </Panel>

      <Panel type="bottom">
        <div className="flex justify-between pb-8">
          <Callout>
            {filteredData?.length ? filteredData?.length : '0'} Users
          </Callout>
          <div className="flex gap-4  w-4/6 justify-end">
            <Dropdown
              placeholder="View"
              className="w-2/4"
              value={parseDropdownActiveHCPFilterValue(activeHCPFilter)}
              onChange={(option: DropdownOption) => {
                setActiveHCPFilter(option.value as ActiveHCPFilter)
              }}
              options={[
                { value: 'ALL', label: 'Show all' },
                { value: USER_HCP_STATUS.FALSE, label: 'No HCP status' },
                {
                  value: USER_HCP_STATUS.PENDING,
                  label: 'Pending HCP status request',
                },
                {
                  value: USER_HCP_STATUS.CONFIRMED,
                  label: 'Confirmed HCP status',
                },
              ]}
            />
            <Dropdown
              clearable
              defaultValue=""
              placeholder="Sort by"
              className="w-2/4"
              onChange={(option: DropdownOption) => {
                setSortMode(option?.value as sortTypes)
              }}
              options={[
                {
                  value: sortTypes.FirstnameAsc,
                  label: 'Firstname Ascending',
                },
                {
                  value: sortTypes.FirstnameDesc,
                  label: 'Firstname Descending',
                },
                {
                  value: sortTypes.LastnameAsc,
                  label: 'Lastname Ascending',
                },
                {
                  value: sortTypes.LastnameDesc,
                  label: 'Lastname Descending',
                },
                {
                  value: sortTypes.EmailAsc,
                  label: 'Email Ascending',
                },
                {
                  value: sortTypes.EmailDesc,
                  label: 'Email Descending',
                },
              ]}
            />
          </div>
        </div>

        <div className="gap-3 flex flex-col">{renderUsers()}</div>

        <div className="w-full pt-10 flex justify-end">
          {data ? (
            <PaginationUsers
              currentPage={currentPage}
              onClick={(page: number) => {
                setCurrentPage(page)
              }}
              nextPage={data.length === pageLimit}
            />
          ) : null}
        </div>
      </Panel>

      {(modalVisible || editModal !== null) && (
        <ModalUser
          visible={true}
          onClose={closeModal}
          onSubmit={refresh}
          defaultValues={
            editModal
              ? {
                  firstname: editModal?.firstname,
                  lastname: editModal?.lastname,
                  email: editModal?.email,
                  role: null,
                  password: null,
                  passwordConfirm: null,
                  HCP: editModal?.HCP,
                }
              : {
                  role: roleId,
                }
          }
          id={editModal ? editModal.id : undefined}
        />
      )}
    </div>
  )
}

export default AppUsersPage
